import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { CloseSVG, ExternalSVG, TrophySVG } from "../custom/svg";
import { GradientAnimation } from "../custom/animations";
import { trackEvent } from "../custom/analytics";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  touch-action: none; /* Prevents touch scrolling on touch devices */
  z-index: 99; // prevents items showing in front
  cursor: pointer;
`;

const PopupContainer = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 15px;
  max-width: 500px;
  width: 50%;
  text-align: center;
  position: fixed; /* Changed from relative to fixed */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust position to center */
  z-index: 1000;
  cursor: auto;

  @media (max-width: 760px) {
    top: 50%; /* Reset top positioning */
    // bottom: 1.5%; /* Position at the bottom */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Adjust position to center */
    width: 85%; /* Set width to 85% on mobile */
    max-width: none; /* Remove max-width constraint */
    border-radius: 15px; /* Rounded top corners */
    // max-height: 30vh; /* Limit height to 50% of viewport */
    overflow-y: hidden; /* Enable scrolling if content overflows */
    padding: 20px; /* Equal padding on all sides */
  }
`;

const CloseButton = styled.button`
  all: unset;
  position: absolute;
  top: 20px;
  right: 20px;
  background: white;
  border-radius: 5px;
  border: none;
  padding-top: 3px;

  &:hover {
    cursor: pointer;
  }

  &:active {
    cursor: pointer;
  }
`;
const PopUpIngredientDiv = styled.div`
  display: flex;
  // padding-left: 15px;
  padding: 10px;
  padding-top: 25px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;
const PopUpTitle = styled.p`
  display: block;
  height: auto;
  font-family: San Francisco Bold;
  font-size: 1.2rem;
  position: static;
  text-align: left;
  margin: 0px;

  @media (max-width: 760px) {
    font-size: 1.3rem;
  }
`;
const PopUpDefinition = styled.p`
  font-size: 14px;
  font-family: San Francisco Regular;
  color: gray;
  text-align: left;
  padding-bottom: 10px;
  margin: 0px;
`;
const ScrollableContent = styled.div`
  max-height: 60vh; /* Restrict the height of the scrollable content */
  overflow-y: auto; /* Makes the content inside this div scrollable */

  /* Hide scrollbar for Chrome, Safari, and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge, and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;
const ProductCompareDiv = styled.div`
  width: 100%;
  position: static;
  padding-top: 5px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding-bottom: 10px;

  @media (min-width: 760px) {
    flex-direction: column;
    width: 96.5%;
  }
`;
const ProductCompareBackground = styled(Link)`
  display: block;
  text-decoration: none;
  color: black;
  width: 95%;
  max-width: 500px;
  height: 80px;
  border-radius: 15px;
  //   padding-top: 5px;
  position: static;
  transition-duration: 0.25s;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  background: linear-gradient(
    135deg,
    ${(props) => (props.color ? props.color : "#d4af37")} 0%,
    ${(props) => (props.color ? props.color : "#d4af37")} 45%,
    white 50%,
    ${(props) => (props.color ? props.color : "#d4af37")} 55%,
    ${(props) => (props.color ? props.color : "#d4af37")} 100%
  );
  background-size: 400% 400%;
  animation: ${GradientAnimation} 10s ease infinite;
  cursor: pointer;
  flex-shrink: 0;

  @media (min-width: 760px) {
    &:hover {
      box-shadow: 0px 0px 10px gray;
    }
  }
`;
const ProductCompareFront = styled.div`
  height: 70px;
  background-color: white;
  border-radius: 10px;
  position: static;
  margin: 5px;
`;
const ProductCompareOuterDiv = styled.div`
  display: flex;
  flex-direction: row;
  //   background: gray;
`;
const ProductCompareTitleDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 70px;
  width: 67%;
  //   margin-left: 0px;
  //   background: yellow;
`;
const CartDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ProductCompareBrand = styled.p`
  font-family: San Francisco Bold;
  font-size: 1rem;
  padding-left: 10px;
  line-height: 1.3rem;
  text-align: left;
  margin: 0px 0px;
  margin-bottom: 3px;
  line-height: 1.3rem;
`;
const ProductCompareFlavor = styled.p`
  font-family: San Francisco Regular;
  font-size: 0.9rem;
  text-align: left;
  color: gray;
  padding-left: 10px;
  margin: 0px 0px;
`;
const ProductImgDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 20%;
  height: 70px;
  // background: yellow;
`;
const TestProductImgDiv = styled.div`
  display: flex;
  justify-content: center;
  // align-items: center;
  margin: 10px auto;
  width: 60px;
  //   background: yellow;
`;
const ProductImg = styled.img`
  display: block;
  height: 50px;
`;
const SvgWrapper = styled.span`
  margin-right: 5px;
  margin-bottom: 3px;
  display: inline-flex;
  vertical-align: middle;

  @media (max-width: 760px) {
    margin-bottom: 2px;
  }
`;

export const ProductCompareHeader = ({ item, filterRank }) => {
  let color;
  let subtitle;

  if (filterRank === 1) {
    color = "#d4af37";
  } else if (filterRank === 2) {
    color = "#C0C0C0";
  } else if (filterRank === 3) {
    color = "#CD7F32";
  }

  if (
    item.category === "preworkout" ||
    item.category === "massgainer" ||
    item.category === "energydrink" ||
    item.category === "proteinbar" ||
    item.category === "omega3" ||
    item.category === "intraworkout" ||
    item.category === "aminoacid" ||
    item.category === "magnesium" ||
    item.category === "turkesterone" ||
    item.category === "multivitamin"
  ) {
    subtitle = item.subtitle;
  }

  if (item.category === "electrolyte") {
    if (item.type === "packet") {
      subtitle = "Electrolyte Packets";
    }
    if (item.type === "powder") {
      subtitle = "Electrolyte Powder";
    }
    if (item.type === "capsule") {
      subtitle = "Electrolyte Capsules";
    }
    if (item.type === "tablet") {
      subtitle = "Electrolyte Tablets";
    }
  }

  if (item.category === "omega3") {
    if (item.type === "capsule") {
      subtitle = item.subtitle + " Capsules";
    }
    if (item.type === "gummy") {
      subtitle = item.subtitle + " Gummies";
    }
    if (item.type === "liquid") {
      subtitle = item.subtitle + " Liquid";
    }
    if (item.type === "oil") {
      subtitle = item.subtitle + " Oil";
    }
    if (item.type === "packet") {
      subtitle = item.subtitle + " Packets";
    }
    if (item.type === "softgel") {
      subtitle = item.subtitle + " Softgels";
    }
  }

  if (item.category === "proteinpowder") {
    if (item.type === "Vegan") {
      subtitle = "Vegan";
    } else if (item.type === "Clear") {
      subtitle = "Clear Whey Isolate";
    } else {
      subtitle = "Whey " + item.type;
    }
  }

  if (item.category === "betaalanine") {
    if (item.type === "powder") {
      subtitle = "Beta Alanine Powder";
    }
    if (item.type === "capsule") {
      subtitle = "Beta Alanine Capsules";
    }
  }

  if (item.category === "citrulline") {
    if (item.type === "powder") {
      subtitle = "Citrulline Powder";
    }
    if (item.type === "capsule") {
      subtitle = "Citrulline Capsules";
    }
  }

  if (item.category === "creatine") {
    if (item.type === "Micronized Monohydrate") {
      subtitle = "Micronized Monohydrate";
    } else {
      subtitle = item.type;
    }
  }

  return (
    <ProductCompareBackground
      to={item.shoplink || "#"}
      color={color}
      target={item.shoplink ? "_blank" : ""}
      rel="noopener noreferrer"
      onClick={(e) => {
        if (!item.shoplink) e.preventDefault(); // Prevent navigation if no shoplink
      }}
    >
      <ProductCompareFront>
        <ProductCompareOuterDiv>
          <ProductImgDiv>
            <TestProductImgDiv>
              <ProductImg
                src={item.imglink}
                alt={item.brand + " " + item.subtitle + " " + item.category}
              />
            </TestProductImgDiv>
          </ProductImgDiv>
          <ProductCompareTitleDiv>
            <div>
              <ProductCompareBrand>{item.brand}</ProductCompareBrand>
              <ProductCompareFlavor>
                {item.subtitle ? item.subtitle : subtitle}
              </ProductCompareFlavor>
            </div>
            {/* <ProductCompareVS>vs</ProductCompareVS>
            <div style={{ marginBottom: "13px" }}>
              <ProductCompareBrand>
                {itemTwo ? itemTwo.brand : "Product Two"}
              </ProductCompareBrand>
              <ProductCompareFlavor>
                {itemTwo ? compareSubmaker(itemTwo) : "Select from search."}
              </ProductCompareFlavor>
            </div> */}
          </ProductCompareTitleDiv>
          {item.shoplink && (
            <CartDiv>
              <ExternalSVG color={"black"} />
            </CartDiv>
          )}
        </ProductCompareOuterDiv>
      </ProductCompareFront>
    </ProductCompareBackground>
  );
};

const categories = [
  {
    category: "preworkout",
    region: "GB",
    active: false,
    title: "Fan Favorite",
    subtitle1: (
      <>
        Ranked amongst the{" "}
        <a
          href="/preworkout?ingredient1=betaalanine&ingredient2=caffeine&ingredient3=citrulline&ingredient4=theanine&ingredient5=tyrosine&region=GB"
          style={{ color: "inherit", textDecoration: "underline" }}
          target="_blank"
          rel="noopener noreferrer"
        >
          top valued Pre-Workout products
        </a>
        , DARKSTIMS features 1.35x the average Beta Alanine serving size, 1.35x
        the typical dose of Caffeine, and is one of the top valued formulas to
        include CDP-Choline. With its superior ingredient profile, it's an easy
        choice for anyone looking to enhance their workout performance.
      </>
    ),
    subtitle2:
      "SUPPHEAD is dedicated to staying neutral and committed to delivering accurate data. We collaborate with top-value supplement brands to provide you with the best possible discounted price.",
    item: {
      brand: "DARKSTIMS",
      subtitle: "Pre-Workout V3",
      imglink: "/images/products/preworkout/preworkout-912.webp",
      shoplink:
        "https://www.darkstims.com/products/darkstims-pre-v2?ref=supphead&variant=42039892901930",
    },
  },
  {
    category: "aminoacid",
    region: "US",
    active: true,
    title: "Fan Favorite",
    subtitle1: (
      <>
        Ranked amongst the{" "}
        <a
          href="/aminoacid?ingredient1=histidine&ingredient2=isoleucine&ingredient3=leucine&ingredient4=lysine&ingredient5=methionine&ingredient6=phenylalanine&ingredient7=threonine&ingredient8=tryptophan&ingredient9=valine&region=US"
          style={{ color: "inherit", textDecoration: "underline" }}
          target="_blank"
          rel="noopener noreferrer"
        >
          top valued Amino Acid products
        </a>
        , Huge Supplements' EAAs feature efficacious doses of all nine Essential
        Amino Acids. EAAs support every step of your fitness journey by
        stimulating muscle protein synthesis, reducing soreness, and aiding
        faster recovery. With superior ingredient value, it's an easy choice for
        anyone looking to enhance workout recovery and maximize results.
      </>
    ),
    subtitle2:
      "SUPPHEAD is dedicated to staying neutral and committed to delivering accurate data. We collaborate with top-value supplement brands to provide you with the best possible discounted price.",
    item: {
      brand: "Huge",
      subtitle: "EAAs",
      imglink: "/images/products/aminoacid/aminoacid-110.webp",
      shoplink:
        "https://hugesupplements.com/discount/SUPPHEAD/?redirect=/products/huge-eaa&variant=49586310217896&aff=233&utm_source=supphead&utm_medium=popup&utm_campaign=eaa",
    },
  },
  {
    category: "creatine",
    region: "US",
    active: true,
    title: "Fan Favorite",
    subtitle1: (
      <>
        Ranked amongst the{" "}
        <a
          href="/creatine?type=monohydrate&region=US"
          style={{ color: "inherit", textDecoration: "underline" }}
          target="_blank"
          rel="noopener noreferrer"
        >
          top valued Creatine products
        </a>
        , Huge Supplements' Creatine Monohydrate features 1.66x the average
        Creatine Per Dollar when compared to other products. With its superior
        value, it's an easy choice for anyone looking to enhance their workout
        performance and muscle-building potential.
      </>
    ),
    subtitle2:
      "SUPPHEAD is dedicated to staying neutral and committed to delivering accurate data. We collaborate with top-value supplement brands to provide you with the best possible discounted price.",
    item: {
      brand: "Huge",
      subtitle: "Monohydrate",
      imglink: "/images/products/creatine/creatine-139.webp",
      shoplink:
        "https://hugesupplements.com/discount/SUPPHEAD/?redirect=/products/creatine-monohydrate-powder&variant=42954959454376&aff=233&utm_source=supphead&utm_medium=popup&utm_campaign=creatine",
    },
  },
  {
    category: "intraworkout",
    region: "US",
    active: true,
    title: "Fan Favorite",
    subtitle1: (
      <>
        Ranked amongst the{" "}
        <a
          href="/intraworkout?ingredient1=carbs&ingredient2=potassium&ingredient3=sodium&region=US"
          style={{ color: "inherit", textDecoration: "underline" }}
          target="_blank"
          rel="noopener noreferrer"
        >
          top valued Intra-Workout products
        </a>
        , 110 Percent's Catalyst features 4.35x the average carbohydrate serving
        size, 1.34x the typical dose of Betaine Anhydrous, and is one of the
        rare formulas to include Alpha GPC and D-Ribose. With its superior
        ingredient profile, it's an easy choice for anyone looking to optimize
        their workout performance.
      </>
    ),
    subtitle2:
      "SUPPHEAD is dedicated to staying neutral and committed to delivering accurate data. We collaborate with top-value supplement brands to provide you with the best possible discounted price.",
    item: {
      brand: "110 Percent",
      subtitle: "Catalyst Intra-Workout",
      imglink: "/images/products/intraworkout/intraworkout-100.webp",
      shoplink:
        "https://papasupps.com/discount/SUPPHEAD/?redirect=/products/catalyst-intra-workout?variant=44787488489706",
    },
  },
  {
    category: "preworkout",
    region: "US",
    active: true,
    title: "Fan Favorite",
    subtitle1: (
      <>
        Ranked amongst the{" "}
        <a
          href="/preworkout?ingredient1=alphagpc&ingredient2=betaalanine&ingredient3=betaine&ingredient4=caffeine&ingredient5=citrulline&ingredient6=huperzinea&ingredient7=taurine&ingredient8=tyrosine&region=US"
          style={{ color: "inherit", textDecoration: "underline" }}
          target="_blank"
          rel="noopener noreferrer"
        >
          top valued Pre-Workout products
        </a>
        , Huge Supplements' Wrecked V2 features 2.46x the average Beta Alanine
        serving size, 2.24x the typical dose of Caffeine, and is one of the top
        valued formulas to include an effective dose of Alpha GPC. With its
        superior ingredient profile, it's an easy choice for anyone looking to
        enhance their workout performance.
      </>
    ),
    subtitle2:
      "SUPPHEAD is dedicated to staying neutral and committed to delivering accurate data. We collaborate with top-value supplement brands to provide you with the best possible discounted price.",
    item: {
      brand: "Huge",
      subtitle: "Wrecked V2",
      imglink: "/images/products/preworkout/preworkout-915.webp",
      shoplink:
        "https://hugesupplements.com/discount/SUPPHEAD/?redirect=/products/wrecked-pre-workout&variant=40116048134312&aff=233&utm_source=supphead&utm_medium=popup&utm_campaign=wrecked",
    },
  },
  {
    category: "proteinpowder",
    region: "US",
    active: false,
    title: "Fan Favorite",
    subtitle1: (
      <>
        Ranked amongst the{" "}
        <a
          href="/proteinpowder?type=isolate&region=US"
          style={{ color: "inherit", textDecoration: "underline" }}
          target="_blank"
          rel="noopener noreferrer"
        >
          top valued Protein Powder products
        </a>
        , Nutricost's Whey Isolate features 1.64x the average Protein Per Dollar
        when compared to other products. With its superior value, it's an easy
        choice for anyone looking to enhance their muscle-building potential.
      </>
    ),
    subtitle2:
      "SUPPHEAD is dedicated to staying neutral and committed to delivering accurate data. We collaborate with top-value supplement brands to provide you with the best possible discounted price.",
    item: {
      brand: "Nutricost",
      subtitle: "Whey Isolate",
      imglink: "/images/products/proteinpowder/proteinpowder-240.webp",
      shoplink: "https://lvnta.com/lv_2iMxk4kkVmvcWye7DB",
    },
  },
  {
    category: "energydrink",
    region: "US",
    active: false,
    title: "Fan Favorite",
    subtitle1: (
      <>
        Ranked amongst the{" "}
        <a
          href="/energydrink?ingredient1=alphagpc&region=US"
          style={{ color: "inherit", textDecoration: "underline" }}
          target="_blank"
          rel="noopener noreferrer"
        >
          top valued Energy Drink products
        </a>
        , Gorilla Mind's sugar free formula features 400mg of Alpha GPC and
        200mg of Caffeine designed to maximize energy and focus. With its
        superior value compared to similar products, it's an easy choice for
        anyone looking to quickly enhance their workout performance.
      </>
    ),
    subtitle2:
      "SUPPHEAD is dedicated to staying neutral and committed to delivering accurate data. We collaborate with top-value supplement brands to provide you with the best possible discounted price.",
    item: {
      brand: "Gorilla Mind",
      subtitle: "Energy Drink",
      imglink: "/images/products/energydrink/energydrink-172.webp",
      shoplink:
        "https://lvnta.com/lv_KN2yRxK6CmO5Jekggt?url=https%3A%2F%2Famazon.com%2Fdp%2FB0CGMDSK74",
    },
  },
  {
    category: "proteinbar",
    region: "US",
    active: false,
    title: "Fan Favorite",
    subtitle1: (
      <>
        Ranked amongst the{" "}
        <a
          href="/proteinbar?type=whey&region=US"
          style={{ color: "inherit", textDecoration: "underline" }}
          target="_blank"
          rel="noopener noreferrer"
        >
          top valued Whey Protein Bars
        </a>
        , Robert Irvine's Fit Crunch Protein Bar features 1.35x the average
        Protein Per Dollar when compared to other Whey Protein Bars. With its
        superior value, it's an easy choice for anyone looking for a quick way
        to hit their protein goals and maximize their muscle-building potential.
      </>
    ),
    subtitle2:
      "SUPPHEAD is dedicated to staying neutral and committed to delivering accurate data. We collaborate with top-value supplement brands to provide you with the best possible discounted price.",
    item: {
      brand: "Robert Irvine",
      subtitle: "Fit Crunch Protein Bar",
      imglink: "/images/products/proteinbar/proteinbar-93.webp",
      shoplink: "https://amzn.to/3YxSsZL",
    },
  },
];

const FanPopUp = ({ canOpen, delay = 4000, userCountry, homePage }) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isTimerActive, setIsTimerActive] = useState(false); // set to false for prod when using timer

  useEffect(() => {
    if (!userCountry) return;

    const activeCategories = categories.filter(
      (i) => i.active && i.region === userCountry
    );

    if (homePage) {
      const randomIndex = Math.floor(Math.random() * activeCategories.length);

      // set to random
      setSelectedCategory(activeCategories[randomIndex]);
      return;
    }

    if (window.location.pathname.includes("/aminoacid")) {
      setSelectedCategory(
        activeCategories.filter(
          (i) => i.category === "aminoacid" && i.region === userCountry
        )[0]
      );
      return;
    }

    if (window.location.pathname.includes("/creatine")) {
      setSelectedCategory(
        activeCategories.filter(
          (i) => i.category === "creatine" && i.region === userCountry
        )[0]
      );
      return;
    }

    if (window.location.pathname.includes("/intraworkout")) {
      setSelectedCategory(
        activeCategories.filter(
          (i) => i.category === "intraworkout" && i.region === userCountry
        )[0]
      );
      return;
    }

    if (window.location.pathname.includes("/preworkout")) {
      setSelectedCategory(
        activeCategories.filter(
          (i) => i.category === "preworkout" && i.region === userCountry
        )[0]
      );
      return;
    }
  }, [userCountry]);

  // TODO: NEED TO UPDATE LOGIC FOR isOpen (it never is called from parent page, this child component does all the work it just needs to check if it can open)

  useEffect(() => {
    // setIsTimerActive(true);
    if (!userCountry || !selectedCategory) return;

    let timer;

    if (canOpen) {
      // Get the last shown date from localStorage
      const localStorageKey = `fan-${selectedCategory.category}-${selectedCategory.region}`;
      const lastShownDate = localStorage.getItem(localStorageKey);
      const now = new Date();

      // Check if the popup was shown more than an hour ago
      if (
        !lastShownDate ||
        now - new Date(lastShownDate) > 60 * 60 * 1000 // 1 hour
      ) {
        // Set a timer to open the popup after the specified delay
        timer = setTimeout(() => {
          trackEvent("Pop-Up", "Viewed: " + selectedCategory.item.subtitle, "");
          setIsTimerActive(true);
          localStorage.setItem(localStorageKey, now.toISOString()); // Update the shown date
        }, delay);
      }
    }

    // Cleanup timer when component unmounts
    return () => clearTimeout(timer);
  }, [delay, canOpen, userCountry, selectedCategory]);

  useEffect(() => {
    if (canOpen && isTimerActive && selectedCategory) {
      document.body.style.overflow = "hidden";
      const themeColorMetaTag = document.querySelector(
        'meta[name="theme-color"]'
      );
      if (themeColorMetaTag)
        themeColorMetaTag.setAttribute("content", "#7F7F7F");
    } else if (canOpen) {
      document.body.style.overflow = "";
      const themeColorMetaTag = document.querySelector(
        'meta[name="theme-color"]'
      );
      if (themeColorMetaTag) themeColorMetaTag.setAttribute("content", "white");
    }

    // return () => {
    //   // bug here it's re-enabling scrolling when ingredient popup is open (triggers this)
    //   document.body.style.overflow = "";
    // };
  }, [canOpen, isTimerActive]);

  if (!canOpen || !isTimerActive || !selectedCategory) return null;

  const handleOverlayClick = (event) => {
    setIsTimerActive(false);
    // onClose(); // Close the popup if clicked outside
  };

  const handlePopupClick = (event) => {
    event.stopPropagation(); // Prevent the click from reaching the overlay
  };

  return (
    selectedCategory.active && (
      <Overlay onClick={handleOverlayClick}>
        <PopupContainer onClick={handlePopupClick}>
          <CloseButton
            onClick={() => {
              setIsTimerActive(false);
            }}
          >
            <CloseSVG color={"black"} />
          </CloseButton>
          <PopUpIngredientDiv>
            <PopUpTitle>
              {selectedCategory.title}{" "}
              <SvgWrapper>
                <TrophySVG />
              </SvgWrapper>
            </PopUpTitle>
            <PopUpDefinition style={{ paddingBottom: "10px" }}>
              {selectedCategory.subtitle1}
            </PopUpDefinition>
          </PopUpIngredientDiv>
          <ScrollableContent>
            <ProductCompareDiv>
              <ProductCompareHeader item={selectedCategory.item} />
            </ProductCompareDiv>
          </ScrollableContent>
          <PopUpIngredientDiv
            style={{ paddingTop: "15px", paddingBottom: "0px" }}
          >
            <PopUpDefinition style={{ fontSize: "10px" }}>
              {selectedCategory.subtitle2}
            </PopUpDefinition>
          </PopUpIngredientDiv>
        </PopupContainer>
      </Overlay>
    )
  );
};

export default FanPopUp;

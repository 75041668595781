import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet-async";

const Container = styled.div`
  width: 85%;
  max-width: 800px;
  margin: 0 auto;
  padding: 25px 0px 50px 0px;
`;

const Title = styled.h1`
  font-size: 1.75rem;
  font-family: San Francisco Bold;
  text-align: left;
  margin: 0px;
  padding-bottom: 20px;

  @media (max-width: 760px) {
    font-size: 1.25rem;
  }
`;

const Section = styled.div`
  margin-bottom: 30px;
`;

const SectionTitle = styled.h2`
  font-size: 1.2rem;
  font-family: San Francisco Bold;
  text-align: left;
  margin: 20px 0px 10px 0px;

  @media (max-width: 760px) {
    font-size: 1.1rem;
  }
`;

const Text = styled.p`
  text-align: left;
  margin: 10px 0px;
  line-height: 1.5;
  font-family: San Francisco Regular;
`;

const Privacy = () => {
  return (
    <Container>
      <Helmet>
        <title>Privacy Policy - SUPPHEAD</title>
        <meta
          name="description"
          content="SUPPHEAD's privacy policy - Learn how we handle and protect your data."
        />
        <meta property="og:title" content="Privacy Policy - SUPPHEAD" />
        <meta
          property="og:description"
          content="SUPPHEAD's privacy policy - Learn how we handle and protect your data."
        />
      </Helmet>

      <Title>Privacy Policy</Title>
      
      <Section>
        <Text>
          Last updated: {new Date().toLocaleDateString()}
        </Text>
        <Text>
          Welcome to SUPPHEAD! This Privacy Policy governs how we collect, use, and store your information when you use the SUPPHEAD mobile application. By using our App, you agree to the data practices described in this policy.
        </Text>
      </Section>

      <Section>
        <SectionTitle>1. Server-Side Data Storage</SectionTitle>
        <Text>
          To provide you with a seamless experience across devices, SUPPHEAD stores certain user data on our secure servers:
        </Text>
        <Text>
          • Account Information: Your credentials are securely stored to enable sign-in functionality.
        </Text>
        <Text>
          • User History & Preferences: Your supplement tracking history, progress logs, preferences, and app settings are stored on our servers.
        </Text>
        <Text>
          • Usage Statistics: Anonymous data about your app interactions may be collected to improve our services.
        </Text>
      </Section>

      <Section>
        <SectionTitle>2. Purpose of Data Storage</SectionTitle>
        <Text>
          We store your information to:
        </Text>
        <Text>
          • Allow you to access your supplement tracking history from any device
        </Text>
        <Text>
          • Enable account authentication and protect your data
        </Text>
        <Text>
          • Provide continuity of experience when upgrading or changing devices
        </Text>
        <Text>
          • Safely back up your tracking progress and preferences
        </Text>
      </Section>

      <Section>
        <SectionTitle>3. Data Security</SectionTitle>
        <Text>
          We implement industry-standard security measures to protect your stored information. However, no method of electronic storage is 100% secure, and we cannot guarantee absolute security.
        </Text>
      </Section>

      <Section>
        <SectionTitle>4. Your Rights</SectionTitle>
        <Text>
          You may request access to your stored data or deletion of your account and associated information by contacting us at the email address below.
        </Text>
      </Section>

      <Section>
        <SectionTitle>5. Contact Us</SectionTitle>
        <Text>
          If you have any questions about this Privacy Policy or our data practices, please contact us at:
        </Text>
        <Text>
          Email: contact@supphead.com
        </Text>
        <Text>
          Website: https://supphead.com
        </Text>
      </Section>
    </Container>
  );
};

export default Privacy; 
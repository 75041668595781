import React, { useState, useEffect, useRef } from "react";
import BrandListCompact from "../components/BrandListCompact";
import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";
import { RightArrowSVG, LockSVG } from "../custom/svg";
import { CircularProgress } from "@mui/material";
import { ReactTyped } from "react-typed";
import mainlogo from "../images/supphead.webp";
import { GradientAnimation } from "../custom/animations";
import {
  TrophySVG,
  ListSVG,
  CloseSVG,
  DollarSVG,
  FilterSVG,
  CartSVG,
} from "../custom/svg";
import { trackEvent } from "../custom/analytics";
import podiumGlyph from "../images/podiumglyph.png";
import { Helmet } from "react-helmet-async";
import FanPopUp from "../components/FanPopUp";
import ChatBot from "../components/ChatBot";
import {
  ResponseDiv,
  ResponseTitle,
  ResponseSubtitle,
} from "../css/examplePage";

const HomeDiv = styled.div`
  @media (max-width: 760px) {
    ${(props) => props.isChatOpen && "display: none;"}
  }
`;
const HomeLogo = styled.img`
  position: static;
  margin-top: 30px;
  margin-bottom: 15px;
  // padding: 5px;
  transition-duration: 0.25s;
  // background: black;
  width: 25%;
  max-width: 350px;

  @media (max-width: 760px) {
    width: 57%;
    max-width: 400px;
    margin-bottom: 10px;
  }
`;

const Subtitle = styled.p`
  width: 50%;
  font-family: San Francisco Regular;
  color: #363636;
  //letter-spacing: 0.7px;
  position: static;
  font-size: 16px;
  transition-duration: 0.25s;
  max-width: 900px;
  margin: 5px;
  margin-bottom: 25px;

  @media (max-width: 760px) {
    font-size: 14px;
    width: 80%;
  }
`;

const PodiumDivMobile = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: static;
  width: 70%;
  max-width: 700px;
  gap: 10px;
  display: flex;

  // padding-bottom: 40px;

  @media (max-width: 760px) {
    display: flex;
    width: 95%;
    // padding-bottom: 30px;
  }
`;

const SearchDiv = styled.form`
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: center;
`;

const CharCountDiv = styled.div`
  width: 70%;
  // background: gray;
  margin-bottom: 5px;
  max-width: 550px;

  @media (max-width: 760px) {
    width: 85%;
  }
`;

const CharCount = styled.p`
  text-align: left;
  margin: 5px 0px;
  font-size: 12px;
  font-family: San Francisco Regular;
  color: gray;

  &.invalid {
    color: red;
  }
`;

const Input = styled.input`
  box-sizing: border-box;
  height: 45px;
  padding: 10px 10px;
  width: 50%;
  max-width: 500px;
  font-size: 16px;
  font-family: San Francisco Regular;
  border: 2px solid black;
  border-radius: 10px;
  -webkit-appearance: none;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;

  &:focus {
    outline: none;
    border-color: #d4af37;
  }

  @media (max-width: 760px) {
    width: 70%;
  }
`;

const SearchButton = styled.button`
  all: unset;
  display: flex;
  box-sizing: border-box;
  padding: auto;
  // border-focus: #d4af37;
  text-decoration: none;
  font-family: San Francisco Bold;
  width: 60px;
  height: 45px;
  background-color: #efefef;
  border-radius: 10px;
  position: static;
  cursor: pointer;
  transition-duration: 0.25s;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;

  @media (min-width: 760px) {
    &:hover {
      box-shadow: 0px 0px 5px black;
    }
  }
`;

const MaintenanceDiv = styled.div`
  padding: 20px 0px;
`;

const MaintenanceTitle = styled.p`
  font-family: San Francisco Bold;
  font-size: 1.4rem;
  position: static;
  text-align: center;
  margin: 0px;

  @media (max-width: 760px) {
    font-size: 1.3rem;
  }
`;

const MaintenanceSubTitle = styled.p`
  font-size: 1rem;
  font-family: San Francisco Regular;
  color: gray;
  text-align: center;
  margin: 0px;
  padding-top: 5px;
  padding-bottom: 20px;
  white-space: pre-line;

  @media (max-width: 760px) {
    font-size: 0.9rem;
  }
`;

const SuggestionDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 15px;
  cursor: grab;
  user-select: none; // Prevent text selection during drag

  &:active {
    cursor: grabbing;
  }

  overflow-x: auto; // Changed from 'scroll' to 'auto'

  @media (min-width: 760px) {
    width: 70%;
    max-width: 700px;
  }

  // Hiding the scrollbar for WebKit browsers
  &::-webkit-scrollbar {
    display: none;
  }

  // For Firefox 64+
  scrollbar-width: none; // Hides the scrollbar in Firefox

  // For Internet Explorer and Edge
  -ms-overflow-style: none; // Hides the scrollbar in IE and Edge
`;
const Suggestion = styled.button`
  all: unset;
  display: block;
  background: #efefef;
  border-radius: 7px;
  position: static;
  cursor: pointer;
  transition-duration: 0.25s;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;

  &:active {
    cursor: grabbing;
  }

  @media (min-width: 760px) {
    &:hover {
      box-shadow: 0px 0px 5px black;
    }
  }
`;

const SuggestionText = styled.div`
  color: black;
  font-family: San Francisco Bold;
  font-size: 1rem;
  position: static;
  text-align: center;
  margin: 5px;
  white-space: nowrap;

  @media (max-width: 760px) {
    font-size: 0.9rem;
  }
`;

const ListRoundedSquare = styled(Link)`
  display: block;
  text-decoration: none;
  color: black;
  width: 95%;
  height: 100px;
  min-height: 75px;
  max-height: 100px;
  border-radius: 15px;
  position: static;
  cursor: pointer;
  transition-duration: 0.25s;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  background: ${(props) =>
    props.color !== "#efefef"
      ? "linear-gradient(135deg, " +
        props.color +
        " 0%, " +
        props.color +
        " 45%, white 50%, " +
        props.color +
        " 55%, " +
        props.color +
        " 100%)"
      : "#efefef"};
  background-size: 400% 400%;
  animation: ${GradientAnimation} 10s ease infinite;

  @media (min-width: 760px) {
    &:hover {
      box-shadow: 0px 0px 10px gray;
    }
  }

  @media (max-width: 760px) {
    flex-direction: column;
    width: 95%;
  }
`;
const FrontRoundedSquare = styled.div`
  height: 80%;
  background-color: white;
  border-radius: 10px;
  position: static;
  margin: 5px;
  padding: 5px;z
`;
const ItemLoadingDiv = styled.div`
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  margin: auto;
  margin-left: 25px;

  @media (max-width: 1000px) {
    margin-left: 20px;
    margin-right: 9px;
  }
`;
const ListContentDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: static;
  height: 100%;
  gap: 10px;
  // padding: 0px 0px 0px 0px;
`;
const RankImage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: static;
  height: 100%;
  gap: 10px;
`;
const ListTitleDiv = styled.div`
  height: auto;
  width: 80%;
`;
const ListTitle = styled.p`
  font-family: San Francisco Bold;
  font-size: 1.25rem;
  text-align: left;
  padding-left: 10px;
  margin-top: 0px;
  margin-bottom: 5px;
`;
const ListSubtitle = styled.p`
  font-family: San Francisco Regular;
  font-size: 0.9rem;
  text-align: left;
  color: gray;
  padding-left: 10px;
  margin-top: 0px;
  margin-bottom: 3px;
`;
const ListImageDiv = styled.div`
  height: 80%;
  aspect-ratio: 1 / 1;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 5px;
`;
const ListImage = styled.img`
  height: 100%;
`;
const ListItemBar = styled.div`
  display: block;
  width: 2px;
  border-radius: 50px;
  background-color: #d4af37;
  // margin-right: 5px;
  height: 72px;
`;
const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  background-color: ${(props) => props.color};
  // margin-top: 30px;

  @media (max-width: 1000px) {
    flex-direction: column;
    padding-bottom: 20px;
  }
`;
const TransparencySection = styled.div`
  display: block;
  width: 30%;

  @media (max-width: 1000px) {
    margin: 0px 10% 0px 10%;
    width: 90%;
  }
`;
const TransparencyItemListDiv = styled.div`
  display: flex;
  font-family: San Francisco Regular;
  flex-direction: column;
  padding-left: 10px;
  padding-bottom: 70px;

  @media (max-width: 1000px) {
    width: 70%;
    padding-bottom: 20px;
  }
`;
const TransparencyItemDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  height: 35px;
`;
const TransparencyTitle = styled.h3`
  font-family: San Francisco Bold;
  font-size: 1.4rem;
  font-weight: normal;
  transition-duration: 0.25s;
  padding-top: 70px;
  // padding-bottom: 0.3em;

  @media (max-width: 1000px) {
    padding-top: 50px;
    font-size: 1.3rem;
  }
`;
const TailoredTitle = styled.h3`
  font-family: San Francisco Bold;
  font-size: 1.4rem;
  font-weight: normal;
  transition-duration: 0.25s;
  padding-top: 70px;

  @media (max-width: 1000px) {
    padding-top: 30px;
    font-size: 1.3rem;
  }
`;
const ExampleTitle = styled.h3`
  font-family: San Francisco Bold;
  font-size: 1.4rem;
  font-weight: normal;
  transition-duration: 0.25s;
  // margin: auto;
  margin: auto;
  padding-bottom: 20px;

  @media (max-width: 1000px) {
    padding-top: 10px;
    font-size: 1.3rem;
  }
`;
const MacbookDiv = styled.div`
  display: block;
  width: 25%;
  max-width: 350px;

  margin-top: auto;
  margin-left: 30px;
  margin-bottom: auto;

  @media (max-width: 1000px) {
    width: 70%;
    margin: auto;
    padding: 20px 0px;
  }
`;
const IphoneDiv = styled.div`
  display: block;
  width: 30%;
  max-width: 400px;
  height: auto;
  margin-right: 40px;
  display: flex; // makes image line up with tailored height
  align-items: flex-end;

  @media (max-width: 1000px) {
    width: 85%;
    margin: auto;
    padding-top: 40px;
  }
`;
const ExampleDiv = styled.div`
  display: block;
  width: 30%;
  max-width: 400px;
  // height: auto;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 40px;
  // margin-bottom: 40px;
  // display: flex; // makes image line up with tailored height
  // align-items: center;

  @media (max-width: 1000px) {
    width: 85%;
    margin: auto;
    // padding-top: 40px;
  }
`;
const Render = styled.img`
  width: 100%;
`;
const TailoredSection = styled.div`
  display: block;
  width: 25%;

  @media (max-width: 1000px) {
    margin: 0% 10% 0px 10%;
    width: 90%;
  }
`;
const ExampleSection = styled.div`
  display: block;
  width: 25%;
  min-width: 300px;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 40px 0px;

  @media (max-width: 1000px) {
    margin: 0% 10% 0px 10%;
    width: 90%;
  }
`;
const AboutSubtitle = styled.div`
  font-size: 1rem;
  font-family: San Francisco Regular;

  @media (max-width: 1000px) {
    font-size: 0.9rem;
  }
`;
const ExampleSubtitle = styled.div`
  font-size: 1rem;
  font-family: San Francisco Regular;
  color: gray;
  // margin-bottom: 30px;

  @media (max-width: 1000px) {
    font-size: 0.9rem;
    margin-right: 20px;
    // margin-bottom: 30px;
  }
`;

const LoginSubtitle = styled(Link)`
  display: block;
  font-family: San Francisco Regular;
  font-size: 14px;
  padding-top: 12px;
  color: gray;
  text-decoration: underline;
  cursor: pointer;
`;
const TabBar = styled.div`
  display: block;
  height: 3px;
  width: 66.5%;
  max-width: 665px;
  border-radius: 50px;
  background-color: #efefef;
  margin-top: 20px;
  margin-bottom: 25px;

  @media (max-width: 1000px) {
    width: 90.25%;
  }
`;
const LastTabBar = styled.div`
  display: block;
  height: 3px;
  width: 66.5%;
  max-width: 665px;
  border-radius: 50px;
  background-color: #efefef;
  margin-top: 30px;
  margin-bottom: 0px;

  @media (max-width: 1000px) {
    width: 90.25%;
    margin-bottom: 30px;
  }
`;
const FilterButtonDiv = styled.div`
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: static;
  padding-top: 20px;

  @media (max-width: 900px) {
    width: 90%;
  }
`;
const ShowMoreButton = styled(Link)`
  text-decoration: none;
  width: 80px;
  height: 40px;
  background-color: #efefef;
  border-radius: 10px;
  position: static;
  cursor: pointer;
  transition-duration: 0.25s;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    box-shadow: 0px 0px 5px black;
  }

  @media (max-width: 760px) {
    &:hover {
      box-shadow: none;
    }
  }
`;
const PodiumGlyphImg = styled.img`
  width: 40px;
  position: static;
`;

export const scroll = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(-50%); }
`;

export const ScrollWrapper = styled.div`
  display: inline-flex;
  gap: 10px;
  animation: ${scroll} ${(props) => (props.home ? "40s" : "200s")} linear
    infinite;

  @media (min-width: 760px) {
    &:hover {
      animation-play-state: paused;
    }
  }
`;

const ItemShowcaseMobile = (props) => {
  return (
    <ListRoundedSquare
      to={props.link}
      onClick={() => {
        window.scroll(0, 0);
        trackEvent("Home-All", "Clicked " + props.name);
      }}
      color={props.color}
    >
      <FrontRoundedSquare>
        <ListContentDiv>
          <RankImage>
            {props.loaded ? (
              <ListImageDiv>
                <ListImage src={props.image} alt={props.name + " thumbnail"} />
              </ListImageDiv>
            ) : (
              <ItemLoadingDiv>
                <CircularProgress color="inherit" />
              </ItemLoadingDiv>
            )}
          </RankImage>
          <ListTitleDiv>
            <ListTitle>{props.name}</ListTitle>
            <ListSubtitle>
              {props.loaded ? props.count + " products" : "Loading products"}
            </ListSubtitle>
          </ListTitleDiv>
          <ListItemBar />
        </ListContentDiv>
      </FrontRoundedSquare>
    </ListRoundedSquare>
  );
};

const Home = (props) => {
  const [message, setMessage] = useState("");
  const [prevMessage, setPrevMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [maintenance, setMaintenance] = useState(false);
  const [loginScreen, setLoginScreen] = useState(false);
  const [exampleLoading, setExampleLoading] = useState(true);
  const [thumbnailData, setThumbnailData] = useState([]);
  const characterLimit = 100;
  const [isChatOpen, setIsChatOpen] = useState(false);

  useEffect(() => {
    if (isChatOpen) {
      document.documentElement.style.overflow = "hidden";
      document.documentElement.style.height = "100%";
      document.body.style.overflow = "hidden";
      document.body.style.height = "100%";
    } else {
      document.documentElement.style.overflow = "";
      document.documentElement.style.height = "";
      document.body.style.overflow = "";
      document.body.style.height = "";
    }

    return () => {
      document.documentElement.style.overflow = "";
      document.documentElement.style.height = "";
      document.body.style.overflow = "";
      document.body.style.height = "";
    };
  }, [isChatOpen]);

  // useEffect(() => {
  //   window.scroll(0, 0);
  // }, [isChatOpen]);

  useEffect(() => {
    if (!props.userCountry) return;

    const fetchExampleData = (url) => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await fetch(url, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          });
          if (response.ok) {
            const data = await response.json();
            resolve(data);
          } else {
            reject(new Error(`HTTP error! status: ${response.status}`));
          }
        } catch (error) {
          reject(error);
        }
      });
    };

    const fetchAllExamples = (userCountry) => {
      const fetchThumbnails = fetchExampleData(
        "/api/thumbnails?region=" + userCountry
      ); //

      setExampleLoading(true);

      Promise.all([fetchThumbnails])
        .then(([dataThumbnails]) => {
          setThumbnailData(dataThumbnails);
          setExampleLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    fetchAllExamples(props.userCountry);
  }, [props.userCountry]);

  const suggestionsList = [
    "recommend a whey protein bar",
    "best sugar free energy drinks",
    "daily multivitamin benefits",
    "joint support vitamins",
    "is turkesterone legit?",
    "stim free preworkout ingredients",
    "what is a pump preworkout?",
    "what are bcaas?",
    "high value electrolyte products",
    "what is whey isolate protein?",
    "high stim preworkout ingredients",
    "preworkouts with yohimbine",
    "do you need intraworkout?",
    "clear vs isolate protein powder",
    "what do omega 3s do?",
    "what are the essential amino acids?",
    "is mass gainer worth buying?",
    "creatine hcl vs monohydrate",
  ];

  const getCategoryData = (loading, items, category) => {
    let index;

    if (loading || !Array.isArray(items) || items.length <= index) {
      return {
        count: "",
        image: "",
      };
    } else {
      index = items.findIndex((i) => i.category === category);
    }

    return {
      count: items[index].count,
      image: items[index].imglink,
    };
  };

  const aminoData = getCategoryData(exampleLoading, thumbnailData, "aminoacid");

  const betaData = getCategoryData(
    exampleLoading,
    thumbnailData,
    "betaalanine"
  );
  const citrullineData = getCategoryData(
    exampleLoading,
    thumbnailData,
    "citrulline"
  );
  const creatineData = getCategoryData(
    exampleLoading,
    thumbnailData,
    "creatine"
  );
  const energyData = getCategoryData(
    exampleLoading,
    thumbnailData,
    "energydrink"
  );
  const electrolyteData = getCategoryData(
    exampleLoading,
    thumbnailData,
    "electrolyte"
  );
  const massData = getCategoryData(exampleLoading, thumbnailData, "massgainer");
  const intraData = getCategoryData(
    exampleLoading,
    thumbnailData,
    "intraworkout"
  );
  const omegaData = getCategoryData(exampleLoading, thumbnailData, "omega3");
  const multiData = getCategoryData(
    exampleLoading,
    thumbnailData,
    "multivitamin"
  );
  const preworkoutData = getCategoryData(
    exampleLoading,
    thumbnailData,
    "preworkout"
  );
  const proteinpowderData = getCategoryData(
    exampleLoading,
    thumbnailData,
    "proteinpowder"
  );
  const proteinbarData = getCategoryData(
    exampleLoading,
    thumbnailData,
    "proteinbar"
  );
  const magnesiumData = getCategoryData(
    exampleLoading,
    thumbnailData,
    "magnesium"
  );
  const turkData = getCategoryData(
    exampleLoading,
    thumbnailData,
    "turkesterone"
  );

  const Categories = [
    {
      name: "Pre-Workout",
      subtitle: "Click to see current Podium.",
      count: preworkoutData.count,
      image: preworkoutData.image,
      link: "/preworkout/examples",
      key: "preworkout",
    },
    {
      name: "Protein Powder",
      subtitle: "Click to see current Podium.",
      count: proteinpowderData.count,
      image: proteinpowderData.image,
      link: "/proteinpowder/examples",
      key: "proteinpowder",
    },
    {
      name: "Energy Drinks",
      subtitle: "Click to see current Podium.",
      count: energyData.count,
      image: energyData.image,
      link: "/energydrink/examples",
      key: "energydrink",
    },
    {
      name: "Electrolytes",
      subtitle: "Click to see current Podium.",
      count: electrolyteData.count,
      image: electrolyteData.image,
      link: "/electrolyte/examples",
      key: "electrolyte",
    },
    {
      name: "Protein Bars",
      subtitle: "Click to see current Podium.",
      count: proteinbarData.count,
      image: proteinbarData.image,
      link: "/proteinbar/examples",
      key: "proteinbar",
    },
    {
      name: "Amino Acids",
      subtitle: "Click to see current Podium.",
      count: aminoData.count,
      image: aminoData.image,
      link: "/aminoacid/examples",
      key: "aminoacid",
    },
    {
      name: "Mass Gainer",
      subtitle: "Click to see current Podium.",
      count: massData.count,
      image: massData.image,
      link: "/massgainer",
      key: "massgainer",
    },
    {
      name: "Intra-Workout",
      subtitle: "Click to see current Podium.",
      count: intraData.count,
      image: intraData.image,
      link: "/intraworkout/examples",
      key: "intraworkout",
    },
    {
      name: "Multivitamin",
      subtitle: "Click to see current Podium.",
      count: multiData.count,
      image: multiData.image,
      link: "/multivitamin/examples",
      key: "multivitamin",
    },
    {
      name: "Beta Alanine",
      subtitle: "Click to see current Podium.",
      count: betaData.count,
      image: betaData.image,
      link: "/betaalanine/examples",
      key: "betaalanine",
    },
    {
      name: "Citrulline",
      subtitle: "Click to see current Podium.",
      count: citrullineData.count,
      image: citrullineData.image,
      link: "/citrulline/examples",
      key: "citrulline",
    },
    {
      name: "Creatine",
      subtitle: "Click to see current Podium.",
      count: creatineData.count,
      image: creatineData.image,
      link: "/creatine/examples",
      key: "creatine",
    },
    {
      name: "Magnesium",
      subtitle: "Click to see current Podium.",
      count: magnesiumData.count,
      image: magnesiumData.image,
      link: "/magnesium/examples",
      key: "magnesium",
    },
    {
      name: "Omega-3",
      subtitle: "Click to see current Podium.",
      count: omegaData.count,
      image: omegaData.image,
      link: "/omega3/examples",
      key: "omega3",
    },
    {
      name: "Turkesterone",
      subtitle: "Click to see current Podium.",
      count: turkData.count,
      image: turkData.image,
      link: "/turkesterone",
      key: "turkesterone",
    },
  ];

  const ref = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const startDragging = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - ref.current.offsetLeft);
    setScrollLeft(ref.current.scrollLeft);
  };

  const stopDragging = (event) => {
    setIsDragging(false);
  };

  const whileDragging = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - ref.current.offsetLeft;
    const walk = (x - startX) * 3; // the *3 is the scroll speed factor
    ref.current.scrollLeft = scrollLeft - walk;
  };

  const handleInputChange = (event) => {
    setMessage(event.target.value);
  };

  const handleButtonClick = (item) => {
    if (!isDragging) {
      setMessage(item);
      setIsChatOpen(true);
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    if (props.isLoggedIn && props.isVerified) {
      setLoginScreen(false);
      setIsChatOpen(true);
    } else {
      setLoginScreen(true);
    }
  };

  return (
    <div>
      <Helmet>
        <title>SUPPHEAD - Supplement Value Rankings</title>
        <meta
          name="description"
          content="Stop falling for supplement marketing. Our website ranks popular
      supplement products by ingredient value to help you find the most
      affordable options. Whether you're looking for a Pre-Workout with
      the highest pump value, a low calorie Protein Powder with the
      highest protein value, or a supplement with the most servings for
      the lowest price, our website provides you with customized rankings
      based on ingredient value. Check out our product rankings above to
      find the highest valued products on the market!"
        />
        <meta
          name="keywords"
          content="supplements, health, fitness, nutrition, supplement reviews, supplement advice, supplement rankings, best protein, best preworkout, best beginner supplements, creatine, pre workout, protein powder, mass gainer, supp, citrulline, beta alanine, stim preworkout"
        />
        <meta property="og:title" content="SUPPHEAD" />
        <meta
          property="og:description"
          content="Stop falling for supplement marketing. Our website ranks popular
      supplement products by ingredient value to help you find the most
      affordable options. Whether you're looking for a Pre-Workout with
      the highest pump value, a low calorie Protein Powder with the
      highest protein value, or a supplement with the most servings for
      the lowest price, our website provides you with customized rankings
      based on ingredient value. Check out our product rankings above to
      find the highest valued products on the market!"
        />
        <link
          rel="preload"
          as="image"
          fetchpriority="high"
          href={mainlogo}
          type="image/webp"
        />
      </Helmet>
      <HomeDiv isChatOpen={isChatOpen}>
        <center>
          <HomeLogo src={mainlogo} logo={false} alt="supphead logo" />
          <Subtitle>Never fall for supplement marketing again.</Subtitle>
          <SearchDiv onSubmit={handleFormSubmit}>
            <Input
              type="text"
              value={message}
              onChange={handleInputChange}
              placeholder="Message SUPPHEAD..."
              maxLength={characterLimit}
              onClick={() =>
                props.isLoggedIn && props.isVerified
                  ? setIsChatOpen(true)
                  : setLoginScreen(true)
              }
              readOnly
            />
            <SearchButton onClick={handleFormSubmit} type="submit">
              {props.isLoggedIn && props.isVerified ? (
                <RightArrowSVG color="black" />
              ) : (
                <LockSVG color="black" />
              )}
            </SearchButton>
          </SearchDiv>
          <CharCountDiv>
            <CharCount
              className={
                message && message.length === characterLimit && "invalid"
              }
            >
              {message ? characterLimit - message.length : characterLimit}{" "}
              {message && characterLimit - message.length === 1
                ? "character"
                : "characters"}{" "}
              left
            </CharCount>
          </CharCountDiv>
          <SuggestionDiv
            ref={ref}
            onMouseDown={startDragging}
            onMouseLeave={stopDragging}
            onMouseUp={stopDragging}
            onMouseMove={whileDragging}
          >
            <ScrollWrapper home={true}>
              {suggestionsList.map(
                (item) =>
                  item &&
                  message !== item &&
                  prevMessage !== item && (
                    <Suggestion
                      key={item}
                      onClick={() =>
                        props.isLoggedIn && props.isVerified
                          ? handleButtonClick(item)
                          : setLoginScreen(true)
                      }
                    >
                      <SuggestionText>{item}</SuggestionText>
                    </Suggestion>
                  )
              )}
            </ScrollWrapper>
          </SuggestionDiv>
          {loading && (
            <>
              <ResponseDiv>
                <ResponseTitle>Searching</ResponseTitle>
                <ResponseSubtitle noUnderline={true}>
                  Loading products...
                </ResponseSubtitle>
              </ResponseDiv>
              <PodiumDivMobile>
                {/* <CircularProgress color="inherit" /> */}
                <BrandListCompact
                  filterRank={1}
                  loading={true}
                ></BrandListCompact>
                <BrandListCompact
                  filterRank={2}
                  loading={true}
                ></BrandListCompact>
                <BrandListCompact
                  filterRank={3}
                  loading={true}
                ></BrandListCompact>
              </PodiumDivMobile>
              <FilterButtonDiv>
                <ShowMoreButton to={null}>
                  <PodiumGlyphImg src={podiumGlyph} alt="show more button" />
                </ShowMoreButton>
              </FilterButtonDiv>
              <TabBar />
            </>
          )}
          {!loading && loginScreen && !props.isLoggedIn && (
            <MaintenanceDiv
              style={{ paddingTop: "0px", paddingBottom: "40px" }}
            >
              <LockSVG />
              <MaintenanceTitle style={{ paddingTop: "10px" }}>
                Login or Sign Up for Free
              </MaintenanceTitle>
              <LoginSubtitle to={"/account"}>
                Login before using SUPPHEAD AI
              </LoginSubtitle>
            </MaintenanceDiv>
          )}
          {!loading && loginScreen && !props.isVerified && props.isLoggedIn && (
            <MaintenanceDiv
              style={{ paddingTop: "0px", paddingBottom: "40px" }}
            >
              <LockSVG />
              <MaintenanceTitle style={{ paddingTop: "10px" }}>
                Please Verify your Account
              </MaintenanceTitle>
              <LoginSubtitle to={"/account"}>
                Verify Account before using SUPPHEAD AI
              </LoginSubtitle>
            </MaintenanceDiv>
          )}
          {!loading && props.apiStatus === 429 && (
            <MaintenanceDiv>
              <MaintenanceTitle>Too Many Requests</MaintenanceTitle>
              <MaintenanceSubTitle>Please slow down!</MaintenanceSubTitle>
            </MaintenanceDiv>
          )}
          {!loading && props.apiStatus === 404 && (
            <MaintenanceDiv>
              <MaintenanceTitle>SUPPHEAD is offline.</MaintenanceTitle>
              <MaintenanceSubTitle>
                Please check back later!
              </MaintenanceSubTitle>
            </MaintenanceDiv>
          )}
          {!loading && maintenance && props.apiStatus === 200 && (
            <MaintenanceDiv>
              <MaintenanceTitle>SUPPHEAD Error</MaintenanceTitle>
              <MaintenanceSubTitle>Please try again.</MaintenanceSubTitle>
            </MaintenanceDiv>
          )}
          {props.apiStatus === 200 && (
            <>
              <ResponseDiv>
                <ResponseTitle>
                  <ReactTyped
                    strings={["All Supplements"]}
                    typeSpeed={30}
                    showCursor={false}
                  />
                </ResponseTitle>
                <ResponseSubtitle
                  noUnderline={true}
                  style={{ cursor: "default" }}
                >
                  {"Click below to see product rankings."}
                </ResponseSubtitle>
              </ResponseDiv>
              <PodiumDivMobile>
                {!exampleLoading ? (
                  Categories.map((category) => (
                    <ItemShowcaseMobile
                      image={category.image}
                      name={category.name}
                      subtitle={category.subtitle}
                      color="#d4af37"
                      link={category.link}
                      count={category.count}
                      loaded={true}
                      key={category.key}
                    />
                  ))
                ) : (
                  <>
                    <ItemShowcaseMobile
                      name={"Pre-Workout"}
                      color="#d4af37"
                      loaded={false}
                    />
                    <ItemShowcaseMobile
                      name={"Protein Powder"}
                      color="#d4af37"
                      loaded={false}
                    />
                    <ItemShowcaseMobile
                      name={"Energy Drinks"}
                      color="#d4af37"
                      loaded={false}
                    />
                    <ItemShowcaseMobile
                      name={"Electrolytes"}
                      color="#d4af37"
                      loaded={false}
                    />
                    <ItemShowcaseMobile
                      name={"Protein Bars"}
                      color="#d4af37"
                      loaded={false}
                    />
                    <ItemShowcaseMobile
                      name={"Amino Acids"}
                      color="#d4af37"
                      loaded={false}
                    />
                    <ItemShowcaseMobile
                      name={"Mass Gainer"}
                      color="#d4af37"
                      loaded={false}
                    />
                    <ItemShowcaseMobile
                      name={"Intra-Workout"}
                      color="#d4af37"
                      loaded={false}
                    />
                    <ItemShowcaseMobile
                      name={"Multivitamin"}
                      color="#d4af37"
                      loaded={false}
                    />
                    <ItemShowcaseMobile
                      name={"Beta Alanine"}
                      color="#d4af37"
                      loaded={false}
                    />
                    <ItemShowcaseMobile
                      name={"Citrulline"}
                      color="#d4af37"
                      loaded={false}
                    />
                    <ItemShowcaseMobile
                      name={"Creatine"}
                      color="#d4af37"
                      loaded={false}
                    />
                    <ItemShowcaseMobile
                      name={"Magnesium"}
                      color="#d4af37"
                      loaded={false}
                    />
                    <ItemShowcaseMobile
                      name={"Omega-3"}
                      color="#d4af37"
                      loaded={false}
                    />
                    '
                    <ItemShowcaseMobile
                      name={"Turkesterone"}
                      color="#d4af37"
                      loaded={false}
                    />
                  </>
                )}
              </PodiumDivMobile>
              {/* <ResponseDiv style={{paddingTop:"20px"}}>
              <ResponseTitle>
                <ReactTyped
                  strings={["SUPPHEAD Favorites"]}
                  typeSpeed={30}
                  showCursor={false}
                />
              </ResponseTitle>
              <ResponseSubtitle>
                {"Our most popular user product rankings."}
              </ResponseSubtitle>
            </ResponseDiv> */}
              <LastTabBar />
            </>
          )}
        </center>

        <InfoContainer color="white">
          <ExampleDiv>
            <Render
              src={"/images/misc/homepage/home-rank-example.webp"}
              alt="how it works"
            />
          </ExampleDiv>
          <ExampleSection>
            <ExampleTitle>How it Works</ExampleTitle>
            <ExampleSubtitle>
              Our website ranks popular supplement products by ingredient value
              to help you find the most affordable options. Whether you're
              looking for a Pre-Workout with the highest pump value, a low
              calorie Protein Powder with the highest protein value, or a
              supplement with the most servings for the lowest price, our
              website provides you with customized rankings based on ingredient
              value. Check out our product rankings above to find the highest
              valued products on the market!
            </ExampleSubtitle>
          </ExampleSection>
        </InfoContainer>
        <InfoContainer color="#efefef">
          <TransparencySection>
            <TransparencyTitle>Built for Transparency</TransparencyTitle>
            <AboutSubtitle>
              <TransparencyItemListDiv>
                <TransparencyItemDiv>
                  <TrophySVG />
                  <p>Products Ranked by Ingredient Value</p>
                </TransparencyItemDiv>
                <TransparencyItemDiv>
                  <ListSVG />
                  <p>Simple UI</p>
                </TransparencyItemDiv>
                <TransparencyItemDiv>
                  <CloseSVG color={"#ed1b24"} />
                  <p>No Marketing Tricks</p>
                </TransparencyItemDiv>
              </TransparencyItemListDiv>
            </AboutSubtitle>
          </TransparencySection>
          <MacbookDiv>
            <Render
              src={"/images/misc/homepage/home-protein-example.webp"}
              alt="macbook example"
            />
          </MacbookDiv>
        </InfoContainer>
        <InfoContainer color="white">
          <IphoneDiv>
            <Render
              src={"/images/misc/homepage/home-search-example.webp"}
              alt="iphone example"
            />
          </IphoneDiv>
          <TailoredSection>
            <TailoredTitle>Tailored for You</TailoredTitle>
            <AboutSubtitle>
              <TransparencyItemListDiv>
                <TransparencyItemDiv>
                  <DollarSVG />
                  <p>Live Prices</p>
                </TransparencyItemDiv>
                <TransparencyItemDiv>
                  <FilterSVG />
                  <p>Filter Products</p>
                </TransparencyItemDiv>
                <TransparencyItemDiv>
                  <CartSVG color={"#d4af37"} />
                  <p>Direct Product Links</p>
                </TransparencyItemDiv>
              </TransparencyItemListDiv>
            </AboutSubtitle>
          </TailoredSection>
        </InfoContainer>
      </HomeDiv>
      {isChatOpen && (
        <ChatBot
          onClose={() => setIsChatOpen(false)}
          suggestionsList={suggestionsList}
          userCountry={props.userCountry}
          ingredients={props.ingredients}
          characterLimit={characterLimit}
          message={message}
          setMessage={setMessage}
          onClick={(e) => e.stopPropagation()}
          onKeyDown={(e) => e.stopPropagation()}
        />
      )}
      {/* <AdPopUp canOpen={true} accessories={props.accessories} /> */}
      <FanPopUp
        canOpen={message.length === 0 && !isChatOpen}
        userCountry={props.userCountry}
        homePage={true}
      />
    </div>
  );
};

export default Home;

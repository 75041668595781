import React, { useEffect, useState, useRef } from "react";
import styled, { keyframes } from "styled-components";
import { CloseSVG, RightArrowSVG } from "../custom/svg";
import mainlogo from "../images/supphead.webp";
import podiumGlyph from "../images/podiumglyph.png";
import { Disclaimer } from "../css/productPage";
import { ReactTyped } from "react-typed";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { TypeAnimation } from "react-type-animation";
import {
  submaker,
  tieBreaker,
  titleMakerAmino,
  titleMakerEnergy,
  titleMakerIntra,
  titleMakerProteinBar,
  subMakerProtein,
  titleMakerPW,
  searchPageGen,
} from "../custom/functions";
import {
  FilterButtonDiv,
  PodiumGlyphImg,
  ShowMoreButton,
} from "../css/examplePage";
import { ProductCompareHeader } from "./FanPopUp";
import PopUp from "./PopUp";
import { ScrollWrapper } from "../pages/Home";
import { CircularProgress, fabClasses } from "@mui/material";

const Overlay = styled.div`
  all: unset;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  touch-action: none; /* Prevents touch scrolling on touch devices */
  z-index: 99; // prevents items showing in front
  cursor: pointer;
  touch-action: none;
  overflow: hidden;
`;

const ChatBotContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100dvw;
  height: 100dvh;
  background-color: white;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  z-index: 100;
  cursor: default;
  //   touch-action: none;

  @media (min-width: 760px) {
    height: calc(100dvh - 15px);
    width: 70%;
    max-width: 740px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 15px;
  }
`;
const Header = styled.div`
  height: 65px;
  background-color: #ffffff;
  //   border: 1px solid;
  border-radius: 10px 10px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const MessagesContainer = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
  margin-bottom: ${(props) =>
    props.rankDivHeight}px; /* Use padding instead of margin */
  height: calc(100dvh - 65px); /* Header height accounted for */
  display: flex;
  flex-direction: column;
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */

  @media (min-width: 760px) {
    padding-left: 20px;
  }
`;

const MessageContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: ${(props) => (props.isUser ? "flex-end" : "flex-start")};
  width: 100%;
  margin-bottom: 10px;
  //   background: #efefef;
`;

const ProfilePicContainer = styled.div`
  width: 32px;
  height: 32px;
  border-style: solid;
  border-color: #efefef;
  border-width: 2px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-top: 1px;
`;

const ProfilePic = styled.img`
  width: 20px;
`;

const Message = styled.div`
  background-color: ${(props) => props.isUser && "white"};
  border: ${(props) => props.isUser && "2px #efefef solid"};
  color: ${(props) => (props.isUser ? "black" : "black")};
  max-width: ${(props) => props.isUser && "80%"};
  padding: 12px 12px;
  border-radius: 15px;
  //   max-width: 80%;
  font-family: San Francisco Regular;
  font-size: ${(props) => (props.isUser ? "0.9rem" : "0.8rem")};
  width: fit-content; // Ensures messages don't stretch full width
  align-self: ${(props) => (props.isUser ? "flex-end" : "flex-start")};
  box-shadow: ${(props) =>
    props.isUser &&
    "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px"};
  margin: 0; // Remove margin-bottom since we're using gap in container
  white-space: pre-wrap; // Preserve newlines and wrap text

  // Optional: Different border radius directions
  //   border-bottom-right-radius: ${(props) => props.isUser && "3px"};
`;
const SearchDiv = styled.form`
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: center;
`;

const CharCountDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) =>
    props.startDiv ? "flex-start" : "space-between"};
  width: 100%;
  //   background: gray;
  padding: 5px 0px;
  //   max-width: 550px;

  @media (max-width: 760px) {
    width: 85%;
    margin: 5px auto;
  }
`;

const CharCount = styled.p`
  text-align: left;
  margin: 5px 0px;
  font-size: 12px;
  font-family: San Francisco Regular;
  color: gray;

  &.invalid {
    color: red;
  }
`;

const RankContainer = styled.div`
  position: fixed;
  bottom: 0px;
  left: 0;
  right: 0;
  background: white;
  padding-top: 20px;
  padding-bottom: 90px;
  border-radius: 15px 15px 0px 0px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px -2px 4px -2px;
  transition-duration: 0.25s;

  flex-direction: column;
  justify-content: center;
  //   align-items: center;
  //   width: 70%;
  //   max-width: 700px;
  //   gap: 10px;
  //   display: flex;
  //   padding: 0px calc(2.5% + 10px);
  //   overflow-x: auto;
  //   scroll-padding: 0px 10px;

  //   @media (max-width: 760px) {
  //     width: 95%;
  //   }
`;

export const ResponseDiv = styled.div`
  flex-direction: column;
  justify-content: left;
  align-items: left;
  position: static;
  width: 100%;
  max-width: 700px;
  display: flex;
`;

const ResponseTitle = styled.h1`
  all: unset;
  display: block;
  height: auto;
  font-family: San Francisco Bold;
  font-size: 1.2rem;
  position: static;
  text-align: left;
  padding-left: calc(7.5% + 10px);
  margin: 0px;

  @media (max-width: 760px) {
    font-size: 1.1rem;
    padding-left: 30px;
    padding-right: 20px;
  }
`;

const ResponseSubtitle = styled.h2`
  all: unset;
  font-size: 0.9rem;
  font-family: San Francisco Regular;
  color: gray;
  text-align: left;
  padding-left: calc(7.5% + 10px);
  padding-right: 30px;
  padding-bottom: 5px;
  margin: 0px;
  padding-top: 5px;
  white-space: pre-line;
  cursor: ${(props) => (props.noUnderline ? "default" : "pointer")};

  @media (max-width: 760px) {
    padding-left: 30px;
    font-size: 0.8rem;
    padding-bottom: 15px;
  }

  @media (min-width: 760px) {
    &:hover {
      text-decoration: ${(props) => (props.noUnderline ? "none" : "underline")};
    }
  }

  &:active {
    text-decoration: ${(props) => (props.noUnderline ? "none" : "underline")};
  }
`;

const ProductContainer = styled.div`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 85%;
  gap: 10px;
  display: flex;
  padding: 10px 7.5%;
  overflow-x: auto;
  //   scroll-padding: 0px 10px;

  //   &::after {
  //     content: "";
  //     flex: 0 0 calc(7.5%); /* Adjust for how much padding you want */
  //   }

  @media (max-width: 760px) {
    width: 95%;
    padding: 0px calc(2.5% + 10px) 15px calc(2.5% + 10px);

    &::after {
      content: "";
      flex: 0 0 10px; /* Adjust for how much padding you want */
    }
  }

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Opera */
  }
`;

const InputContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;

  @media (min-width: 760px) {
    padding: 0px calc(7.5%);
  }
`;

const Input = styled.input`
  box-sizing: border-box;
  height: 45px;
  padding: 10px 10px;
  width: 100%;
  font-size: 16px;
  font-family: San Francisco Regular;
  border: 2px solid black;
  border-radius: 10px;
  -webkit-appearance: none;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;

  &:focus {
    outline: none;
    border-color: #d4af37;
  }

  @media (max-width: 760px) {
    width: 70%;
    max-width: 500px;
  }
`;

const SearchButton = styled.button`
  all: unset;
  display: flex;
  box-sizing: border-box;
  padding: auto;
  // border-focus: #d4af37;
  text-decoration: none;
  font-family: San Francisco Bold;
  width: 60px;
  height: 45px;
  background-color: #efefef;
  border-radius: 10px;
  position: static;
  cursor: pointer;
  transition-duration: 0.25s;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;

  @media (min-width: 760px) {
    &:hover {
      box-shadow: 0px 0px 5px black;
    }
  }
`;
const CloseButton = styled.button`
  all: unset;
  position: absolute;
  top: 20px;
  right: 20px;
  background: white;
  border-radius: 5px;
  border: none;
  padding-top: 3px;

  &:hover {
    cursor: pointer;
  }

  &:active {
    cursor: pointer;
  }
`;
const ResponseH3 = styled.h3`
  all: unset;
  font-family: San Francisco Bold;
  font-size: 1.2rem;
  position: static;
  text-align: left;
  display: block;
  padding: 10px 0px 10px 0px;

  @media (max-width: 760px) {
    font-size: 1rem;
  }
`;
const ResponseStrong = styled.strong`
  all: unset;
  font-family: San Francisco Bold;
`;
const ResponseP = styled.p`
  font-family: San Francisco Regular;
  position: static;
  text-align: left;
  margin: 0px 0px 10px 0px;
`;
const ResponseOl = styled.ol`
  //   all: unset;
  font-family: San Francisco Regular;
  position: static;
  text-align: left;
  margin: 0px 0px 10px 0px;
`;
const ResponseLi = styled.li`
  //   all: unset;
  font-family: San Francisco Regular;
  position: static;
  text-align: left;
  margin: 0px 0px 20px 0px;
`;
const HeaderLogo = styled.img`
  position: static;
  height: 20px;
  padding: 30px 30px 15px 30px;
  transition-duration: 0.25s;
  width: auto;
  cursor: pointer;

  @media (max-width: 760px) {
    padding-left: 20px;
  }
`;
const HomeLogo = styled.img`
  position: static;
  margin-top: 15%;
  margin-bottom: 15px;
  // padding: 5px;
  transition-duration: 0.25s;
  // background: black;
  width: 250px;

  @media (max-width: 760px) {
    width: 50%;
    margin-top: 25%;
    max-width: 400px;
    margin-bottom: 10px;
  }
`;
const Subtitle = styled.p`
  width: 50%;
  font-family: San Francisco Regular;
  color: #363636;
  //letter-spacing: 0.7px;
  position: static;
  font-size: 16px;
  transition-duration: 0.25s;
  max-width: 900px;
  margin: 5px;
  margin-bottom: 30px;

  @media (max-width: 760px) {
    font-size: 14px;
    width: 80%;
  }
`;

const SuggestionDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  //   margin-bottom: 20px;
  padding-top: 5px;
  padding-bottom: 15px;
  padding-left: 20px;
  padding-right: 20px;
  //   margin-bottom: 15px;
  cursor: grab;
  user-select: none; // Prevent text selection during drag

  &:active {
    cursor: grabbing;
  }

  overflow-x: auto; // Changed from 'scroll' to 'auto'

  // Hiding the scrollbar for WebKit browsers
  &::-webkit-scrollbar {
    display: none;
  }

  // For Firefox 64+
  scrollbar-width: none; // Hides the scrollbar in Firefox

  // For Internet Explorer and Edge
  -ms-overflow-style: none; // Hides the scrollbar in IE and Edge
`;

const Suggestion = styled.button`
  all: unset;
  display: block;
  background: #efefef;
  border-radius: 7px;
  position: static;
  cursor: pointer;
  transition-duration: 0.25s;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;

  &:active {
    cursor: grabbing;
  }

  @media (min-width: 760px) {
    &:hover {
      box-shadow: 0px 0px 5px black;
    }
  }
`;

const SuggestionText = styled.div`
  color: black;
  font-family: San Francisco Bold;
  font-size: 1rem;
  position: static;
  text-align: center;
  margin: 5px;
  white-space: nowrap;

  @media (max-width: 760px) {
    font-size: 0.9rem;
  }
`;

const LoadingDiv = styled.div`
  display: flex;
  //   justify-content: center;
  //   align-items: center;
  width: 80%;
  height: 100%;
`;

function responseMaker(filter, category, patent, type = null) {
  if (category === "proteinpowder") {
    if (filter === "all") {
      return "All Protein Powder";
    }
    if (filter === "isolate") {
      return "Whey Isolate Protein Powder";
    }
    if (filter === "concentrate") {
      return "Whey Concentrate Protein Powder";
    }
    if (filter === "clear") {
      return "Clear Whey Isolate Protein Powder";
    }
    if (filter === "vegan") {
      return "Vegan Protein Powder";
    }

    return "All Protein Powder";
  }

  if (category === "betaalanine") {
    if (filter === "powder") {
      return "Beta Alanine Powder";
    }
    if (filter === "capsule") {
      return "Beta Alanine Capsules";
    }

    return "All Beta Alanine";
  }

  if (category === "creatine") {
    let title = "Creatine";

    if (filter === "all" && patent !== "true") {
      return "All Creatine";
    }

    if (filter === "monohydrate") {
      title = "Creatine Monohydrate";
    }
    if (filter === "micronized") {
      title = "Micronized Creatine Monohydrate";
    }
    if (filter === "hcl") {
      title = "Creatine HCl";
    }

    if (patent === "true") {
      title = "Patented " + title;
    }

    return title;
  }

  if (category === "citrulline") {
    if (filter === "powder") {
      return "Citrulline Powder";
    }
    if (filter === "capsule") {
      return "Citrulline Capsules";
    }

    return "All Citrulline";
  }

  if (category === "electrolyte") {
    if (filter === "powder") {
      return "Electrolyte Powder";
    }
    if (filter === "capsule") {
      return "Electrolyte Capsules";
    }
    if (filter === "tablet") {
      return "Electrolyte Tablets";
    }
    if (filter === "packet") {
      return "Electrolyte Packets";
    }
    if (filter === "all") {
      return "All Electrolytes";
    }
    return "All Electrolytes";
  }

  if (category === "omega3") {
    if (filter === "capsule") {
      return "Omega-3 Capsules";
    }
    if (filter === "gummy") {
      return "Omega-3 Gummies";
    }
    if (filter === "liquid") {
      return "Omega-3 Liquid";
    }
    if (filter === "oil") {
      return "Omega-3 Oil";
    }
    if (filter === "packet") {
      return "Omega-3 Packets";
    }
    if (filter === "softgel") {
      return "Omega-3 Softgels";
    }

    return "All Omega-3s";
  }

  if (category === "magnesium") {
    if (filter === "powder") {
      return "Magnesium Powder";
    }
    if (filter === "capsule") {
      return "Magnesium Capsules";
    }
    if (filter === "tablet") {
      return "Magnesium Tablets";
    }
    if (filter === "packet") {
      return "Magnesium Packets";
    }

    return "All Magnesium";
  }

  if (category === "multivitamin") {
    if (filter === "capsule") {
      return "Multivitamin Capsules";
    }
    if (filter === "gummy") {
      return "Multivitamin Gummies";
    }
    if (filter === "liquid") {
      return "Multivitamin Liquid";
    }
    if (filter === "tablet") {
      return "Multivitamin Tablets";
    }

    return "All Multivitamins";
  }

  if (category === "turkesterone") {
    return "All Turkesterone";
  }

  if (category === "proteinbar") {
    return titleMakerProteinBar(filter, type);
  }
}

const fadeInOut = keyframes`
  0% { opacity: 0; }
  10% { opacity: 1; }
  90% { opacity: 1; }
  100% { opacity: 0; }
`;

const AnimatedText = styled.span`
  animation: ${fadeInOut} 2.5s ease-in-out;
  margin: 0px;
  padding: 0px;
`;

const LoadingAnimation = () => {
  const phrases = [
    "Thinking...",
    "Reasoning...",
    "Analyzing...",
    "Processing...",
    "Searching...",
    "Finding studies...",
    "Generating response...",
  ];
  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
  const [shuffledPhrases, setShuffledPhrases] = useState([]);

  useEffect(() => {
    // Function to shuffle phrases
    const shuffleArray = (array) => {
      return array.sort(() => Math.random() - 0.5);
    };

    // Shuffle and set the phrases
    const shuffled = shuffleArray([...phrases]);
    setShuffledPhrases(shuffled);

    let index = 0;
    const interval = setInterval(() => {
      index += 1;
      if (index >= shuffled.length) {
        // Stop when all phrases are shown once
        clearInterval(interval);
      } else {
        setCurrentPhraseIndex(index);
      }
    }, 2500); // Change text every 2.5 seconds

    return () => clearInterval(interval); // Clean up the interval
  }, []);

  return (
    <AnimatedText key={currentPhraseIndex}>
      {phrases[currentPhraseIndex]}
    </AnimatedText>
  );
};

// TODO: show scroll bar on messages for mobile

const ChatBot = ({
  onClose,
  suggestionsList,
  userCountry,
  ingredients,
  characterLimit,
  message,
  setMessage,
}) => {
  //   const [message, setMessage] = useState("");
  const [messages, setMessages] = useState(
    []
    // { text: "why should i buy preworkout? it seems like a money grab", isUser: true },
    // { text: testMessage, isUser: false },
    // { text: "thanks bro", isUser: true },
  );
  const lastMessageRef = useRef(null);
  const inputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState(null);
  const [productInfo, setProductInfo] = useState({
    category: null,
    ingredients: [],
    excludes: [],
    filter: null,
    rank: null,
    type: null,
    patent: null,
  });
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const rankDivRef = useRef(null);
  const [rankDivHeight, setRankDivHeight] = useState(0);

  const [isPopupOpen, setPopupOpen] = useState(false);
  const [popupIngredients, setPopupIngredients] = useState(null);
  const [popupIngredientList, setPopupIngredientList] = useState(null);

  const handlePopUp = (ingredients, ingredientList) => {
    setPopupOpen(true);
    setPopupIngredients(ingredients);
    setPopupIngredientList(ingredientList);
  };

  const splitSuggestions = () => {
    const chunkSize = Math.ceil(suggestionsList.length / 3);
    return [
      suggestionsList.slice(0, chunkSize),
      suggestionsList.slice(chunkSize, chunkSize * 2),
      suggestionsList.slice(chunkSize * 2),
    ];
  };

  // Create 3 duplicated arrays (each looped 10 times)
  const createLoopedArrays = (arrays) => {
    return arrays.map((arr) => {
      const looped = [];
      for (let i = 0; i < 10; i++) {
        looped.push(...arr);
      }
      return looped;
    });
  };

  // Usage
  const [array1, array2, array3] = splitSuggestions();
  const duplicatedArrays = createLoopedArrays([array1, array2, array3]);

  const handleSubmit = async (message) => {
    // if (e) e.preventDefault();

    if (message.trim() === "") return;

    // ensures last message is done loading and typing
    if (
      messages.length > 0 &&
      (!messages[messages.length - 1].typingDone ||
        messages[messages.length - 1].loading)
    )
      return;

    setMessages((prev) => [
      ...prev,
      { text: message, isUser: true },
      { isUser: false, loading: true },
    ]);
    setMessage("");

    if (inputRef.current) {
      inputRef.current.blur();
    }

    try {
      setLoading(true);
      let response = "";

      if (process.env.REACT_APP_ENV === "production") {
        response = await fetch("/api/chat?q=" + message);
      } else {
        response = await fetch("/api/dev-chat?q=" + message, {
          headers: {
            "Content-Type": "application/json",
            dev_key: process.env.REACT_APP_DEV_KEY,
          },
        });
      }

      if (!response.ok) {
        setMessages((prev) => {
          // Remove the last message (loading message) and add the new message
          const updatedMessages = prev.slice(0, -1); // Remove the last message
          return [
            ...updatedMessages,
            {
              text: "An error has occured. Please try again.",
              isUser: false,
              typingDone: false,
            },
          ];
        });
        setLoading(false);
        throw new Error(`HTTP error! status: ${response.status}`);
      } else {
        const data = await response.json();
        if (
          data.response !==
            "I can only give you answers related to supplements." &&
          !data.data
        )
          fetchProducts(data.response, message);
        else if (
          data.response !==
            "I can only give you answers related to supplements." &&
          data.data
        ) {
          // historical chat and api call were found and returned
          const ctg = response.headers.get("category");
          const ing1 = response.headers.get("ingredient1");
          const ing2 = response.headers.get("ingredient2");
          const ing3 = response.headers.get("ingredient3");
          const ing4 = response.headers.get("ingredient4");
          const ing5 = response.headers.get("ingredient5");
          const ing6 = response.headers.get("ingredient6");
          const ing7 = response.headers.get("ingredient7");
          const ing8 = response.headers.get("ingredient8");
          const ing9 = response.headers.get("ingredient9");
          const ing10 = response.headers.get("ingredient10");
          const ing11 = response.headers.get("ingredient11");
          const ing12 = response.headers.get("ingredient12");
          const ing13 = response.headers.get("ingredient13");
          const ing14 = response.headers.get("ingredient14");
          const ing15 = response.headers.get("ingredient15");
          const ex1 = response.headers.get("exclude1");
          const ex2 = response.headers.get("exclude2");
          const ex3 = response.headers.get("exclude3");
          const ex4 = response.headers.get("exclude4");
          const ex5 = response.headers.get("exclude5");
          const reqFilter = response.headers.get("filter");
          const reqRank = response.headers.get("rank");
          const reqPatent = response.headers.get("patent");
          const reqType = response.headers.get("type");

          setProducts(data.data);
          setProductInfo({
            category: ctg,
            ingredients: [
              ing1,
              ing2,
              ing3,
              ing4,
              ing5,
              ing6,
              ing7,
              ing8,
              ing9,
              ing10,
              ing11,
              ing12,
              ing13,
              ing14,
              ing15,
            ],
            excludes: [ex1, ex2, ex3, ex4, ex5],
            filter: reqFilter,
            rank: reqRank,
            type: reqType,
            patent: reqPatent,
          });
        }
        setMessages((prev) => {
          // Remove the last message (loading message) and add the new message
          const updatedMessages = prev.slice(0, -1); // Remove the last message
          return [
            ...updatedMessages,
            { text: data.response, isUser: false, typingDone: false },
          ];
        });
        setLoading(false);
      }
    } catch (e) {
      setMessages((prev) => {
        // Remove the last message (loading message) and add the new message
        const updatedMessages = prev.slice(0, -1); // Remove the last message
        return [
          ...updatedMessages,
          {
            text: "An error has occured. Please try again.",
            isUser: false,
            typingDone: false,
          },
        ];
      });
      setLoading(false);
      //   setMaintenance(true);
      console.error("There was a problem with the fetch chat operation:", e);
    }
  };

  const fetchProducts = async (query, prompt) => {
    try {
      //   setLoading(true);
      let response = "";

      if (process.env.REACT_APP_ENV === "production") {
        response = await fetch("/api/chat-analyze", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: query,
            prompt: prompt,
          }),
        });
      } else {
        response = await fetch("/api/dev-chat-analyze", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            dev_key: process.env.REACT_APP_DEV_KEY,
          },
          body: JSON.stringify({
            query: query,
            prompt: prompt,
          }),
        });
      }

      if (!response.ok) {
        setProductInfo({
          category: null,
          ingredients: [],
          excludes: [],
          filter: null,
          rank: null,
          type: null,
          patent: null,
        });
        setProducts(null);
        throw new Error(`HTTP error! status: ${response.status}`);
      } else {
        const data = await response.json();
        const ctg = response.headers.get("category");
        const ing1 = response.headers.get("ingredient1");
        const ing2 = response.headers.get("ingredient2");
        const ing3 = response.headers.get("ingredient3");
        const ing4 = response.headers.get("ingredient4");
        const ing5 = response.headers.get("ingredient5");
        const ing6 = response.headers.get("ingredient6");
        const ing7 = response.headers.get("ingredient7");
        const ing8 = response.headers.get("ingredient8");
        const ing9 = response.headers.get("ingredient9");
        const ing10 = response.headers.get("ingredient10");
        const ing11 = response.headers.get("ingredient11");
        const ing12 = response.headers.get("ingredient12");
        const ing13 = response.headers.get("ingredient13");
        const ing14 = response.headers.get("ingredient14");
        const ing15 = response.headers.get("ingredient15");
        const ex1 = response.headers.get("exclude1");
        const ex2 = response.headers.get("exclude2");
        const ex3 = response.headers.get("exclude3");
        const ex4 = response.headers.get("exclude4");
        const ex5 = response.headers.get("exclude5");
        const reqFilter = response.headers.get("filter");
        const reqRank = response.headers.get("rank");
        const reqPatent = response.headers.get("patent");
        const reqType = response.headers.get("type");

        setProducts(data);
        setProductInfo({
          category: ctg,
          ingredients: [
            ing1,
            ing2,
            ing3,
            ing4,
            ing5,
            ing6,
            ing7,
            ing8,
            ing9,
            ing10,
            ing11,
            ing12,
            ing13,
            ing14,
            ing15,
          ],
          excludes: [ex1, ex2, ex3, ex4, ex5],
          filter: reqFilter,
          rank: reqRank,
          type: reqType,
          patent: reqPatent,
        });
        // console.log(data.slice(0, 3));
        // setLoading(false);
      }
    } catch (e) {
      //   setLoading(false);
      //   setMaintenance(true);
      console.error("There was a problem with the fetch product operation:", e);
      setProductInfo({
        category: null,
        ingredients: [],
        excludes: [],
        filter: null,
        rank: null,
        type: null,
        patent: null,
      });
      setProducts(null);
    }
  };

  useEffect(() => {
    if (message) {
      handleSubmit(message); // Trigger handleSubmit after setting the initial state
    }
    // Function to update the height dynamically
    const updateHeight = () => {
      if (rankDivRef.current) {
        setRankDivHeight(rankDivRef.current.offsetHeight);
      }
    };

    // Use ResizeObserver to track height changes
    const observer = new ResizeObserver(() => {
      updateHeight();
    });

    if (rankDivRef.current) {
      observer.observe(rankDivRef.current);
      updateHeight(); // Initial update
    }

    // Cleanup the observer when component unmounts
    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  useEffect(() => {
    if (!productInfo) return;

    switch (productInfo.category) {
      case "aminoacid":
        setTitle(titleMakerAmino(productInfo.filter, productInfo.ingredients));
        setSubtitle(
          submaker(
            productInfo.ingredients,
            [],
            ingredients.filter((i) => i.category === productInfo.category)
          )
        );
        setPopupIngredients(productInfo.ingredients);
        setPopupIngredientList(
          ingredients.filter((i) => i.category === productInfo.category)
        );
        return;
      case "betaalanine":
        setTitle(
          responseMaker(
            productInfo.filter,
            productInfo.category,
            productInfo.patent,
            productInfo.type
          )
        );
        setSubtitle("Ranked by Beta Alanine value.");
        setPopupIngredients(["betaalanine"]);
        setPopupIngredientList(
          ingredients.filter((i) => i.category === "preworkout")
        );
        return;
      case "citrulline":
        setTitle(
          responseMaker(
            productInfo.filter,
            productInfo.category,
            productInfo.patent,
            productInfo.type
          )
        );
        setSubtitle("Ranked by Citrulline value.");
        setPopupIngredients(["citrulline"]);
        setPopupIngredientList(
          ingredients.filter((i) => i.category === "preworkout")
        );
        return;
      case "creatine":
        setTitle(
          responseMaker(
            productInfo.filter,
            productInfo.category,
            productInfo.patent,
            productInfo.type
          )
        );
        setSubtitle("Ranked by Creatine value.");
        setPopupIngredients(
          productInfo.filter !== "all" ? [productInfo.filter] : ["monohydrate"]
        );
        setPopupIngredientList(
          ingredients.filter((i) => i.category === "creatine")
        );
        return;
      case "electrolyte":
        setTitle(
          responseMaker(
            productInfo.filter,
            productInfo.category,
            productInfo.patent,
            productInfo.type
          )
        );
        setSubtitle(
          submaker(
            productInfo.ingredients,
            [],
            ingredients.filter((i) => i.category === productInfo.category)
          )
        );
        setPopupIngredients(productInfo.ingredients);
        setPopupIngredientList(
          ingredients.filter((i) => i.category === productInfo.category)
        );
        return;
      case "energydrink":
        setTitle(
          titleMakerEnergy(
            productInfo.excludes,
            ingredients.filter((i) => i.category === productInfo.category)
          )
        );
        setSubtitle(
          submaker(
            productInfo.ingredients,
            [],
            ingredients.filter((i) => i.category === productInfo.category)
          )
        );
        setPopupIngredients(productInfo.ingredients);
        setPopupIngredientList(
          ingredients.filter((i) => i.category === productInfo.category)
        );
        return;
      case "intraworkout":
        setTitle(
          titleMakerIntra(
            productInfo.excludes,
            ingredients.filter((i) => i.category === productInfo.category)
          )
        );
        setSubtitle(
          submaker(
            productInfo.ingredients,
            [],
            ingredients.filter((i) => i.category === productInfo.category)
          )
        );
        setPopupIngredients(productInfo.ingredients);
        setPopupIngredientList(
          ingredients.filter((i) => i.category === productInfo.category)
        );
        return;
      case "magnesium":
        setTitle(
          responseMaker(
            productInfo.filter,
            productInfo.category,
            productInfo.patent,
            productInfo.type
          )
        );
        setSubtitle(
          submaker(
            productInfo.ingredients,
            [],
            ingredients.filter((i) => i.category === productInfo.category)
          )
        );
        setPopupIngredients(productInfo.ingredients);
        setPopupIngredientList(
          ingredients.filter((i) => i.category === productInfo.category)
        );
        return;
      case "massgainer":
        setTitle("All Mass Gainer");
        setSubtitle("Ranked by Highest Calories and Protein value.");
        setPopupIngredients(productInfo.ingredients);
        setPopupIngredientList(
          ingredients.filter((i) => i.category === productInfo.category)
        );
        return;
      case "multivitamin":
        setTitle(
          responseMaker(
            productInfo.filter,
            productInfo.category,
            productInfo.patent,
            productInfo.type
          )
        );
        setSubtitle(
          submaker(
            productInfo.ingredients,
            [],
            ingredients.filter((i) => i.category === productInfo.category)
          )
        );
        setPopupIngredients(productInfo.ingredients);
        setPopupIngredientList(
          ingredients.filter((i) => i.category === productInfo.category)
        );
        return;
      case "omega3":
        setTitle(
          responseMaker(
            productInfo.filter,
            productInfo.category,
            productInfo.patent,
            productInfo.type
          )
        );
        setSubtitle(
          submaker(
            productInfo.ingredients,
            [],
            ingredients.filter((i) => i.category === productInfo.category)
          )
        );
        setPopupIngredients(productInfo.ingredients);
        setPopupIngredientList(
          ingredients.filter((i) => i.category === productInfo.category)
        );
        return;
      case "preworkout":
        setTitle(
          titleMakerPW(
            productInfo.excludes,
            ingredients.filter((i) => i.category === productInfo.category)
          )
        );
        setSubtitle(
          submaker(
            productInfo.ingredients,
            [],
            ingredients.filter((i) => i.category === productInfo.category)
          )
        );
        setPopupIngredients(productInfo.ingredients);
        setPopupIngredientList(
          ingredients.filter((i) => i.category === productInfo.category)
        );
        return;
      case "proteinbar":
        setTitle(
          responseMaker(
            productInfo.filter,
            productInfo.category,
            productInfo.patent,
            productInfo.type
          )
        );
        setSubtitle(subMakerProtein(productInfo.rank));
        setPopupIngredients(
          productInfo.rank
            ? [
                "calories",
                productInfo.filter === "all" ? "protein" : productInfo.filter,
              ]
            : [productInfo.filter === "all" ? "protein" : productInfo.filter]
        );
        setPopupIngredientList(
          ingredients.filter((i) => i.category === productInfo.category)
        );
        return;
      case "proteinpowder":
        setTitle(
          responseMaker(
            productInfo.filter,
            productInfo.category,
            productInfo.patent,
            productInfo.type
          )
        );
        setSubtitle(subMakerProtein(productInfo.rank));
        setPopupIngredients(
          productInfo.rank
            ? [
                "calories",
                productInfo.filter === "all" ||
                productInfo.filter === "glutenfree"
                  ? "protein"
                  : productInfo.filter,
              ]
            : [
                productInfo.filter === "all" ||
                productInfo.filter === "glutenfree"
                  ? "protein"
                  : productInfo.filter,
              ]
        );
        setPopupIngredientList(
          ingredients.filter((i) => i.category === productInfo.category)
        );
        return;
      case "turkesterone":
        setTitle(
          responseMaker(
            productInfo.filter,
            productInfo.category,
            productInfo.patent,
            productInfo.type
          )
        );
        setSubtitle("Ranked by Turkesterone value.");
        setPopupIngredients(["turkesterone"]);
        setPopupIngredientList(
          ingredients.filter((i) => i.category === productInfo.category)
        );
        return;
      default:
        return;
    }
  }, [productInfo]);

  return (
    <Overlay onClick={() => onClose()}>
      <ChatBotContainer onClick={(event) => event.stopPropagation()}>
        <Header>
          {messages.length > 0 && (
            <HeaderLogo
              src={mainlogo}
              logo={false}
              alt="supphead logo"
              onClick={onClose}
            />
          )}
          <CloseButton onClick={onClose}>
            <CloseSVG color={"black"} />
          </CloseButton>
        </Header>

        {messages.length > 0 && (
          <MessagesContainer rankDivHeight={rankDivHeight}>
            {messages.map((message, index) => (
              <MessageContainer key={index} isUser={message.isUser}>
                {!message.isUser && (
                  <ProfilePicContainer>
                    <ProfilePic src={podiumGlyph} />
                  </ProfilePicContainer>
                )}
                {!message.isUser ? (
                  message.loading ? (
                    <Message
                      isUser={message.isUser}
                      ref={
                        index === messages.length - 1 ? lastMessageRef : null
                      }
                      style={{ whiteSpace: "normal", color: "gray" }}
                    >
                      <LoadingAnimation />
                    </Message>
                  ) : message.typingDone ? (
                    <Message
                      isUser={message.isUser}
                      //   ref={index === messages.length - 1 ? lastMessageRef : null}
                      style={{ whiteSpace: "normal" }}
                    >
                      <ReactMarkdown
                        remarkPlugins={[remarkGfm]}
                        components={{
                          h3: ResponseH3,
                          strong: ResponseStrong,
                          p: ResponseP,
                          ol: ResponseOl,
                          li: ResponseLi,
                        }}
                      >
                        {message.text}
                      </ReactMarkdown>
                    </Message>
                  ) : (
                    <Message
                      //   ref={index === messages.length - 1 ? lastMessageRef : null}
                      isUser={message.isUser}
                    >
                      <TypeAnimation
                        sequence={[
                          message.text,
                          () =>
                            setMessages((prev) => {
                              // Ensure there are messages to update
                              if (prev.length === 0) return prev;

                              // Create a copy of the previous messages and update the last element
                              const updatedMessages = [...prev];
                              updatedMessages[updatedMessages.length - 1] = {
                                ...updatedMessages[updatedMessages.length - 1],
                                typingDone: true,
                              };

                              return updatedMessages;
                            }),
                        ]}
                        speed={100}
                        cursor={false}
                      />
                    </Message>
                  )
                ) : (
                  <Message
                    ref={index === messages.length - 1 ? lastMessageRef : null}
                    isUser={message.isUser}
                  >
                    {message.text}
                  </Message>
                )}
              </MessageContainer>
            ))}
          </MessagesContainer>
        )}
        {messages.length === 0 && (
          <center>
            <HomeLogo src={mainlogo} logo={false} alt="supphead logo" />
            <Subtitle>Never fall for supplement marketing again.</Subtitle>
            <SuggestionDiv>
              <ScrollWrapper>
                {duplicatedArrays[0].map(
                  (item, index) =>
                    item &&
                    message !== item && (
                      <Suggestion
                        key={`${item}-${index}`}
                        onClick={() => {
                          handleSubmit(item);
                        }}
                      >
                        <SuggestionText>{item}</SuggestionText>
                      </Suggestion>
                    )
                )}
              </ScrollWrapper>
            </SuggestionDiv>
            <SuggestionDiv>
              <ScrollWrapper>
                {duplicatedArrays[1].map(
                  (item, index) =>
                    item &&
                    message !== item && (
                      <Suggestion
                        key={`${item}-${index}`}
                        onClick={() => {
                          handleSubmit(item);
                        }}
                      >
                        <SuggestionText>{item}</SuggestionText>
                      </Suggestion>
                    )
                )}
              </ScrollWrapper>
            </SuggestionDiv>
            <SuggestionDiv>
              <ScrollWrapper>
                {duplicatedArrays[2].map(
                  (item, index) =>
                    item &&
                    message !== item && (
                      <Suggestion
                        key={`${item}-${index}`}
                        onClick={() => {
                          handleSubmit(item);
                        }}
                      >
                        <SuggestionText>{item}</SuggestionText>
                      </Suggestion>
                    )
                )}
              </ScrollWrapper>
            </SuggestionDiv>
            <Disclaimer style={{ paddingTop: "20px" }}>
              These statements have not been evaluated by the Food and Drug
              Administration. These products are not intended to diagnose,
              treat, cure or prevent any disease.
            </Disclaimer>
          </center>
        )}

        <RankContainer ref={rankDivRef}>
          {products && products.length > 0 && messages.length > 0 && (
            <>
              <ResponseDiv>
                <ResponseTitle>
                  <ReactTyped
                    strings={[title]}
                    typeSpeed={30}
                    showCursor={false}
                  />
                </ResponseTitle>
                <ResponseSubtitle noUnderline={true}>
                  {/* <SvgWrapper>
                    <IngredientEyeSVG />
                  </SvgWrapper> */}
                  {subtitle}
                </ResponseSubtitle>
              </ResponseDiv>
              <ProductContainer>
                {tieBreaker(products)
                  .filter((item) => item.currentRank <= 3)
                  .map(
                    (item, index) =>
                      item && (
                        <ProductCompareHeader
                          item={item}
                          filterRank={item.currentRank}
                          oldRank={item.oldRank}
                          ticker={true}
                          loading={false}
                          ai={true}
                          userCountry={userCountry}
                          key={index}
                          filter={products}
                          chatBot={true}
                          //   style={
                          //     item.index === products.length - 1 && {
                          //       paddingRight: "10px",
                          //     }
                          //   }
                          //   filterLink={searchPageGen(
                          //     category,
                          //     filter,
                          //     rank,
                          //     patent,
                          //     type,
                          //     [
                          //       ingredient1,
                          //       ingredient2,
                          //       ingredient3,
                          //       ingredient4,
                          //       ingredient5,
                          //       ingredient6,
                          //       ingredient7,
                          //       ingredient8,
                          //       ingredient9,
                          //       ingredient10,
                          //     ],
                          //     [exclude1, exclude2, exclude3, exclude4, exclude5],
                          //     [],
                          //     props.ingredients
                          //   )}
                          //   setFilter={getFilterFunction(category)}
                          //   setFilterLink={getFilterLinkFunction(category)}
                        />
                      )
                  )}
                <FilterButtonDiv
                  style={{
                    paddingTop: "0px",
                    paddingLeft: "10px",
                    paddingRight: "15px",
                  }}
                >
                  <ShowMoreButton
                    to={searchPageGen(
                      productInfo.category,
                      productInfo.filter,
                      productInfo.rank,
                      productInfo.patent,
                      productInfo.type,
                      productInfo.ingredients,
                      productInfo.excludes,
                      [],
                      ingredients.filter(
                        (i) => i.category === productInfo.category
                      )
                    )}
                    onClick={onClose}
                    style={{ height: "70px" }}
                  >
                    <PodiumGlyphImg src={podiumGlyph} alt="show more button" />
                  </ShowMoreButton>
                </FilterButtonDiv>
              </ProductContainer>
            </>
          )}
        </RankContainer>

        <InputContainer>
          <SearchDiv
            onSubmit={(e) => {
              e.preventDefault(); // Prevent form from reloading the page
              handleSubmit(message);
            }}
          >
            <Input
              type="text"
              value={message}
              ref={inputRef}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Message SUPPHEAD..."
              maxLength={characterLimit}
              onBlur={() =>
                setTimeout(() => {
                  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                }, 100)
              }
            />
            <SearchButton type="submit">
              {messages.length > 0 &&
              (!messages[messages.length - 1].typingDone ||
                messages[messages.length - 1].loading) ? (
                <CircularProgress color="inherit" size={25} />
              ) : (
                <RightArrowSVG />
              )}
            </SearchButton>
          </SearchDiv>
          <CharCountDiv startDiv={messages.length === 0}>
            <CharCount
              className={
                message && message.length === characterLimit && "invalid"
              }
            >
              {message ? characterLimit - message.length : characterLimit}{" "}
              {message && characterLimit - message.length === 1
                ? "character"
                : "characters"}{" "}
              left
            </CharCount>
            {messages.length > 0 && (
              <CharCount>AI generated, for reference only.</CharCount>
            )}
          </CharCountDiv>
        </InputContainer>
        <PopUp
          isOpen={isPopupOpen}
          onClose={() => setPopupOpen(false)}
          ingredients={popupIngredients}
          ingredientList={popupIngredientList}
        />
      </ChatBotContainer>
    </Overlay>
  );
};

export default ChatBot;

import React, { useState, useEffect, lazy, Suspense } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Fonts from "./fonts/Fonts";
import Loading from "./components/Loading";
import Error from "./pages/Error";
import styled from "styled-components";
import Home from "./pages/Home";
import { pageview, initializeGA } from "./custom/analytics";
import { HelmetProvider } from "react-helmet-async";
import PreExample from "./pages/PreExample";
import PreFetchFilter from "./pages/PreFetchFilter";
import PreworkoutProduct from "./pages/PreworkoutProduct";

import IntraExample from "./pages/IntraExample";
import IntraFetchFilter from "./pages/IntraFetchFilter";
import IntraProduct from "./pages/IntraProduct";

import ProteinExample from "./pages/ProteinExample";
import ProteinFetchFilter from "./pages/ProteinFetchFilter";
import ProteinPowderProduct from "./pages/ProteinPowderProduct";

import ElectrolyteExample from "./pages/ElectrolyteExample";
import ElectrolyteFetchFilter from "./pages/ElectrolyteFetchFilter";
import ElectrolyteProduct from "./pages/ElectrolyteProduct";

import MagnesiumExample from "./pages/MagnesiumExample";
import MagnesiumFetchFilter from "./pages/MagnesiumFetchFilter";
import MagnesiumProduct from "./pages/MagnesiumProduct";

import MultivitaminExample from "./pages/MultivitaminExample";
import MultivitaminFetchFilter from "./pages/MultivitaminFetchFilter";
import MultivitaminProduct from "./pages/MultivitaminProduct";

import TurkFetchFilter from "./pages/TurkFetchFilter";
import TurkProduct from "./pages/TurkProduct";

import ProteinBarExample from "./pages/ProteinBarExample";
import ProteinBarFetchFilter from "./pages/ProteinBarFetchFilter";
import ProteinBarProduct from "./pages/ProteinBarProduct";

import EnergyDrinkExample from "./pages/EnergyDrinkExample";
import EnergyFetchFilter from "./pages/EnergyFetchFilter";
import EnergyDrinkProduct from "./pages/EnergyDrinkProduct";

import OmegaExample from "./pages/OmegaExample";
import OmegaFetchFilter from "./pages/OmegaFetchFilter";
import OmegaProduct from "./pages/OmegaProduct";

import AminoExample from "./pages/AminoExample";
import AminoFetchFilter from "./pages/AminoFetchFilter";
import AminoProduct from "./pages/AminoProduct";

import MassFetchFilter from "./pages/MassFetchFilter";
import MassProduct from "./pages/MassProduct";

import CreatineExample from "./pages/CreatineExample";
import CreatineFetchFilter from "./pages/CreatineFetchFilter";
import CreatineProduct from "./pages/CreatineProduct";

import BetaExample from "./pages/BetaExample";
import BetaFetchFilter from "./pages/BetaFetchFilter";
import BetaProduct from "./pages/BetaProduct";

import CitrullineExample from "./pages/CitrullineExample";
import CitrullineFetchFilter from "./pages/CitrullineFetchFilter";
import CitrullineProduct from "./pages/CitrullineProduct";

import About from "./pages/About";
import Account from "./pages/Account";
import Compare from "./pages/Compare";
import ArticlePage from "./pages/ArticlePage";
import Article from "./pages/Article";
import Privacy from "./pages/Privacy";

// const PreExample = lazy(() => import("./pages/PreExample"));
// const PreFetchFilter = lazy(() => import("./pages/PreFetchFilter"));
// const PreworkoutProduct = lazy(() => import("./pages/PreworkoutProduct"));

// const IntraExample = lazy(() => import("./pages/IntraExample"));
// const IntraFetchFilter = lazy(() => import("./pages/IntraFetchFilter"));
// const IntraProduct = lazy(() => import("./pages/IntraProduct"));

// const ProteinExample = lazy(() => import("./pages/ProteinExample"));
// const ProteinFetchFilter = lazy(() => import("./pages/ProteinFetchFilter"));
// const ProteinPowderProduct = lazy(() => import("./pages/ProteinPowderProduct"));

// const ElectrolyteExample = lazy(() => import("./pages/ElectrolyteExample"));
// const ElectrolyteFetchFilter = lazy(() =>
//   import("./pages/ElectrolyteFetchFilter")
// );
// const ElectrolyteProduct = lazy(() => import("./pages/ElectrolyteProduct"));

// const MagnesiumExample = lazy(() => import("./pages/MagnesiumExample"));
// const MagnesiumFetchFilter = lazy(() => import("./pages/MagnesiumFetchFilter"));
// const MagnesiumProduct = lazy(() => import("./pages/MagnesiumProduct"));

// const TurkFetchFilter = lazy(() => import("./pages/TurkFetchFilter"));

// const ProteinBarExample = lazy(() => import("./pages/ProteinBarExample"));
// const ProteinBarFetchFilter = lazy(() =>
//   import("./pages/ProteinBarFetchFilter")
// );
// const ProteinBarProduct = lazy(() => import("./pages/ProteinBarProduct"));

// const EnergyDrinkExample = lazy(() => import("./pages/EnergyDrinkExample"));
// const EnergyFetchFilter = lazy(() => import("./pages/EnergyFetchFilter"));
// const EnergyDrinkProduct = lazy(() => import("./pages/EnergyDrinkProduct"));

// const OmegaExample = lazy(() => import("./pages/OmegaExample"));
// const OmegaFetchFilter = lazy(() => import("./pages/OmegaFetchFilter"));
// const OmegaProduct = lazy(() => import("./pages/OmegaProduct"));

// const AminoExample = lazy(() => import("./pages/AminoExample"));
// const AminoFetchFilter = lazy(() => import("./pages/AminoFetchFilter"));
// const AminoProduct = lazy(() => import("./pages/AminoProduct"));

// const MassFetchFilter = lazy(() => import("./pages/MassFetchFilter"));
// const MassProduct = lazy(() => import("./pages/MassProduct"));

// const CreatineExample = lazy(() => import("./pages/CreatineExample"));
// const CreatineFetchFilter = lazy(() => import("./pages/CreatineFetchFilter"));
// const CreatineProduct = lazy(() => import("./pages/CreatineProduct"));

// const BetaExample = lazy(() => import("./pages/BetaExample"));
// const BetaFetchFilter = lazy(() => import("./pages/BetaFetchFilter"));
// const BetaProduct = lazy(() => import("./pages/BetaProduct"));

// const CitrullineExample = lazy(() => import("./pages/CitrullineExample"));
// const CitrullineFetchFilter = lazy(() =>
//   import("./pages/CitrullineFetchFilter")
// );
// const CitrullineProduct = lazy(() => import("./pages/CitrullineProduct"));

// const About = lazy(() => import("./pages/About"));
// const Account = lazy(() => import("./pages/Account"));
// const Compare = lazy(() => import("./pages/Compare"));
// const ArticlePage = lazy(() => import("./pages/ArticlePage"));
// const Article = lazy(() => import("./pages/Article"));

const PageContainer = styled.div`
  position: relative;
  min-height: 100vh;
  background: white;
`;
const ContentWrap = styled.div`
  padding-bottom: 100px;
`;

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    pageview(location.pathname + location.search);
  }, [location]);
};

const App = () => {
  const [loading, setLoading] = useState(true);
  const [navBarOpen, setNavBarOpen] = useState(false);
  const [apiStatus, setApiStatus] = useState(200);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isVerified, setVerified] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [userfname, setUserfname] = useState("");
  const [userCountry, setUserCountry] = useState(
    process.env.REACT_APP_ENV === "development" ? "US" : null
  ); // defaults to US products
  const [preFilter, setPreFilter] = useState([]);
  const [preFilterLink, setPreFilterLink] = useState(null);
  const [proteinBarFilter, setProteinBarFilter] = useState([]);
  const [proteinBarFilterLink, setProteinBarFilterLink] = useState(null);
  const [proteinFilter, setProteinFilter] = useState([]);
  const [proteinFilterLink, setProteinFilterLink] = useState(null);
  const [electrolyteFilter, setElectrolyteFilter] = useState([]);
  const [electrolyteFilterLink, setElectrolyteFilterLink] = useState(null);
  const [energyDrinkFilter, setEnergyDrinkFilter] = useState([]);
  const [energyDrinkFilterLink, setEnergyDrinkFilterLink] = useState(null);
  const [creatineFilter, setCreatineFilter] = useState([]);
  const [creatineFilterLink, setCreatineFilterLink] = useState(null);
  const [citrullineFilter, setCitrullineFilter] = useState([]);
  const [citrullineFilterLink, setCitrullineFilterLink] = useState(null);
  const [betaFilter, setBetaFilter] = useState([]);
  const [betaFilterLink, setBetaFilterLink] = useState(null);
  const [massFilter, setMassFilter] = useState([]);
  const [massFilterLink, setMassFilterLink] = useState(null);
  const [aminoFilter, setAminoFilter] = useState([]);
  const [aminoFilterLink, setAminoFilterLink] = useState(null);
  const [omegaFilter, setOmegaFilter] = useState([]);
  const [omegaFilterLink, setOmegaFilterLink] = useState(null);
  const [intraFilter, setIntraFilter] = useState([]);
  const [intraFilterLink, setIntraFilterLink] = useState(null);
  const [magnesiumFilter, setMagnesiumFilter] = useState([]);
  const [magnesiumFilterLink, setMagnesiumFilterLink] = useState(null);
  const [multivitaminFilter, setMultivitaminFilter] = useState([]);
  const [multivitaminFilterLink, setMultivitaminFilterLink] = useState(null);
  const [turkFilter, setTurkFilter] = useState([]);
  const [turkFilterLink, setTurkFilterLink] = useState(null);
  const [accessories, setAccessories] = useState(null);
  const [ingredients, setIngredients] = useState([]);

  useEffect(() => {
    initializeGA();
    const checkUser = async () => {
      try {
        const response = await fetch(`/api/userCheck`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        const userData = await response.json();
        if (response.ok) {
          // console.log(data);
          // user doesn't exist anymore in db
          setLoggedIn(true);
          setVerified(userData.verified);
          setUserEmail(userData.email);
          setUserfname(userData.fname);
          setUserCountry(userData.country);
        } else {
          setUserCountry(userData.country);
          // console.log(userData.country);
          try {
            const response = await fetch("/api/logout", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
            });

            if (response.ok) {
              const data = await response.json();
              // Handle successful response
              // console.log(data)
            } else {
              // Handle server errors
              const errorData = await response.json();

              console.log(errorData.error || "Logout failed");
            }
          } catch (error) {
            console.error("Error during logout:", error);
          }
          setLoggedIn(false);
          setVerified(false);
          setUserEmail("");
          setUserfname("");
          setLoading(false);
        }
      } catch (error) {
        console.error("Error during user check:", error);
      }
    };
    const getAccessories = async () => {
      try {
        const response = await fetch(`/api/accessories`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        const data = await response.json();
        if (response.ok) {
          // getting accessories
          setAccessories(data);
        }
      } catch (error) {
        console.error("Error getting accessories:", error);
      }
    };

    const getIngredients = async () => {
      try {
        const response = await fetch(`/api/ingredients`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        const data = await response.json();
        if (response.ok) {
          // getting accessories
          setIngredients(data);
        }
      } catch (error) {
        console.error("Error getting accessories:", error);
      }
    };

    setLoading(true);
    getAccessories();
    getIngredients();
    if (process.env.REACT_APP_ENV === "development") {
      setLoggedIn(true);
      setVerified(true);
      setLoading(false);
      // checkUser(); // comment out
    } else {
      checkUser();
    }
  }, []);

  usePageTracking(); // Google Analytics

  // console.log(userCountry);

  return (
    <>
      <HelmetProvider>
        <Fonts />
        <PageContainer>
          <ContentWrap>
            <NavBar
              loading={loading}
              isLoggedIn={isLoggedIn}
              setNavBarOpen={setNavBarOpen}
            />
            {/* <Suspense fallback={<Loading apiStatus={apiStatus} />}> */}
            <Routes>
              <Route path="/about" element={<About />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route
                path="/article"
                element={
                  <ArticlePage
                    userCountry={userCountry}
                    accessories={accessories}
                  />
                }
              />
              <Route
                path="/article/:id"
                element={
                  <Article
                    userCountry={userCountry}
                    setProteinFilter={setProteinFilter}
                    setProteinFilterLink={setProteinFilterLink}
                    setPreFilter={setPreFilter}
                    setPreFilterLink={setPreFilterLink}
                    setProteinBarFilter={setProteinBarFilter}
                    setProteinBarFilterLink={setProteinBarFilterLink}
                    accessories={accessories}
                    ingredients={ingredients}
                  />
                }
              />
              <Route
                path="/account"
                element={
                  <Account
                    isLoggedIn={isLoggedIn}
                    isVerified={isVerified}
                    userEmail={userEmail}
                    userfname={userfname}
                    userCountry={userCountry}
                    setUserCountry={setUserCountry}
                    setLoggedIn={setLoggedIn}
                    setVerified={setVerified}
                    setUserEmail={setUserEmail}
                    setUserfname={setUserfname}
                  />
                }
              />
              <Route
                path="/"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <Home
                      apiStatus={apiStatus}
                      isLoggedIn={isLoggedIn}
                      isVerified={isVerified}
                      userCountry={userCountry}
                      setPreFilter={setPreFilter}
                      setPreFilterLink={setPreFilterLink}
                      setProteinFilter={setProteinFilter}
                      setProteinFilterLink={setProteinFilterLink}
                      setProteinBarFilter={setProteinBarFilter}
                      setProteinBarFilterLink={setProteinBarFilterLink}
                      setElectrolyteFilter={setElectrolyteFilter}
                      setElectrolyteFilterLink={setElectrolyteFilterLink}
                      setCreatineFilter={setCreatineFilter}
                      setCreatineFilterLink={setCreatineFilterLink}
                      setCitrullineFilter={setCitrullineFilter}
                      setCitrullineFilterLink={setCitrullineFilterLink}
                      setBetaFilter={setBetaFilter}
                      setBetaFilterLink={setBetaFilterLink}
                      setMassFilter={setMassFilter}
                      setMassFilterLink={setMassFilterLink}
                      setAminoFilter={setAminoFilter}
                      setAminoFilterLink={setAminoFilterLink}
                      setEnergyDrinkFilter={setEnergyDrinkFilter}
                      setEnergyDrinkFilterLink={setEnergyDrinkFilterLink}
                      setOmegaFilter={setOmegaFilter}
                      setOmegaFilterLink={setOmegaFilterLink}
                      setIntraFilter={setIntraFilter}
                      setIntraFilterLink={setIntraFilterLink}
                      setMagnesiumFilter={setMagnesiumFilter}
                      setMagnesiumFilterLink={setMagnesiumFilterLink}
                      setTurkFilter={setTurkFilter}
                      setTurkFilterLink={setTurkFilterLink}
                      setMultivitaminFilter={setMultivitaminFilter}
                      setMultivitaminFilterLink={setMultivitaminFilterLink}
                      accessories={accessories}
                      ingredients={ingredients}
                    />
                  )
                }
              />
              <Route
                path="/compare"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <Compare
                      apiStatus={apiStatus}
                      isLoggedIn={isLoggedIn}
                      isVerified={isVerified}
                      userCountry={userCountry}
                      accessories={accessories}
                      ingredients={ingredients}
                    />
                  )
                }
              />
              <Route
                path="/aminoacid"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <AminoFetchFilter
                      userCountry={userCountry}
                      setAminoFilter={setAminoFilter}
                      setAminoFilterLink={setAminoFilterLink}
                      accessories={accessories}
                      ingredients={ingredients.filter(
                        (i) => i.category === "aminoacid"
                      )}
                    /> // added productGroup
                  )
                }
              />
              <Route
                path="/aminoacid/examples"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <AminoExample
                      userCountry={userCountry}
                      setAminoFilter={setAminoFilter}
                      setAminoFilterLink={setAminoFilterLink}
                      accessories={accessories}
                      ingredients={ingredients.filter(
                        (i) => i.category === "aminoacid"
                      )}
                    /> // added productGroup
                  )
                }
              />
              <Route
                path="/aminoacid/:brand/:subtitle/:type/:flavor/:servings/:region"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <AminoProduct
                      navBarOpen={navBarOpen}
                      aminoFilter={aminoFilter}
                      aminoFilterLink={aminoFilterLink}
                      ingredients={ingredients.filter(
                        (i) => i.category === "aminoacid"
                      )}
                    />
                  )
                }
              />
              <Route
                path="/betaalanine"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <BetaFetchFilter
                      userCountry={userCountry}
                      setBetaFilter={setBetaFilter}
                      setBetaFilterLink={setBetaFilterLink}
                      accessories={accessories}
                      ingredients={ingredients}
                    /> // added productGroup
                  )
                }
              />
              <Route
                path="/betaalanine/examples"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <BetaExample
                      userCountry={userCountry}
                      setBetaFilter={setBetaFilter}
                      setBetaFilterLink={setBetaFilterLink}
                      accessories={accessories}
                      ingredients={ingredients}
                    /> // added productGroup
                  )
                }
              />
              <Route
                path="/betaalanine/:brand/:type/:flavor/:servings/:region"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <BetaProduct
                      navBarOpen={navBarOpen}
                      betaFilter={betaFilter}
                      betaFilterLink={betaFilterLink}
                      ingredients={ingredients}
                    />
                  )
                }
              />
              <Route
                path="/citrulline"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <CitrullineFetchFilter
                      userCountry={userCountry}
                      setCitrullineFilter={setCitrullineFilter}
                      setCitrullineFilterLink={setCitrullineFilterLink}
                      accessories={accessories}
                      ingredients={ingredients}
                    />
                  )
                }
              />
              <Route
                path="/citrulline/examples"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <CitrullineExample
                      userCountry={userCountry}
                      setCitrullineFilter={setCitrullineFilter}
                      setCitrullineFilterLink={setCitrullineFilterLink}
                      accessories={accessories}
                      ingredients={ingredients}
                    />
                  )
                }
              />
              <Route
                path="/citrulline/:brand/:type/:flavor/:servings/:region"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <CitrullineProduct
                      navBarOpen={navBarOpen}
                      citrullineFilter={citrullineFilter}
                      citrullineFilterLink={citrullineFilterLink}
                      ingredients={ingredients}
                    />
                  )
                }
              />
              <Route
                path="/creatine"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <CreatineFetchFilter
                      userCountry={userCountry}
                      setCreatineFilter={setCreatineFilter}
                      setCreatineFilterLink={setCreatineFilterLink}
                      accessories={accessories}
                      ingredients={ingredients.filter(
                        (i) => i.category === "creatine"
                      )}
                    />
                  )
                }
              />
              <Route
                path="/creatine/examples"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <CreatineExample
                      userCountry={userCountry}
                      setCreatineFilter={setCreatineFilter}
                      setCreatineFilterLink={setCreatineFilterLink}
                      accessories={accessories}
                      ingredients={ingredients.filter(
                        (i) => i.category === "creatine"
                      )}
                    />
                  )
                }
              />
              <Route
                path="/creatine/:brand/:type/:flavor/:servings/:region/:patent"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <CreatineProduct
                      navBarOpen={navBarOpen}
                      creatineFilter={creatineFilter}
                      creatineFilterLink={creatineFilterLink}
                      ingredients={ingredients.filter(
                        (i) => i.category === "creatine"
                      )}
                    />
                  )
                }
              />
              <Route
                path="/electrolyte"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <ElectrolyteFetchFilter
                      userCountry={userCountry}
                      setElectrolyteFilter={setElectrolyteFilter}
                      setElectrolyteFilterLink={setElectrolyteFilterLink}
                      accessories={accessories}
                      ingredients={ingredients.filter(
                        (i) => i.category === "electrolyte"
                      )}
                    />
                  )
                }
              />
              <Route
                path="/electrolyte/examples"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <ElectrolyteExample
                      userCountry={userCountry}
                      setElectrolyteFilter={setElectrolyteFilter}
                      setElectrolyteFilterLink={setElectrolyteFilterLink}
                      accessories={accessories}
                      ingredients={ingredients.filter(
                        (i) => i.category === "electrolyte"
                      )}
                    />
                  )
                }
              />
              <Route
                path="/electrolyte/:brand/:type/:flavor/:servings/:region"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <ElectrolyteProduct
                      navBarOpen={navBarOpen}
                      electrolyteFilter={electrolyteFilter}
                      electrolyteFilterLink={electrolyteFilterLink}
                      ingredients={ingredients.filter(
                        (i) => i.category === "electrolyte"
                      )}
                    />
                  )
                }
              />
              <Route
                path="/energydrink/examples"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <EnergyDrinkExample
                      userCountry={userCountry}
                      setEnergyDrinkFilter={setEnergyDrinkFilter}
                      setEnergyDrinkFilterLink={setEnergyDrinkFilterLink}
                      accessories={accessories}
                      ingredients={ingredients.filter(
                        (i) => i.category === "energydrink"
                      )}
                    />
                  )
                }
              />
              <Route
                path="/energydrink"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <EnergyFetchFilter
                      userCountry={userCountry}
                      setEnergyDrinkFilter={setEnergyDrinkFilter}
                      setEnergyDrinkFilterLink={setEnergyDrinkFilterLink}
                      accessories={accessories}
                      ingredients={ingredients.filter(
                        (i) => i.category === "energydrink"
                      )}
                    />
                  )
                }
              />
              <Route
                path="/energydrink/:brand/:subtitle/:flavor/:servings/:region"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <EnergyDrinkProduct
                      navBarOpen={navBarOpen}
                      energyDrinkFilter={energyDrinkFilter}
                      energyDrinkFilterLink={energyDrinkFilterLink}
                      ingredients={ingredients.filter(
                        (i) => i.category === "energydrink"
                      )}
                    />
                  )
                }
              />
              <Route
                path="/intraworkout/examples"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <IntraExample
                      userCountry={userCountry}
                      setIntraFilter={setIntraFilter}
                      setIntraFilterLink={setIntraFilterLink}
                      accessories={accessories}
                      ingredients={ingredients.filter(
                        (i) => i.category === "intraworkout"
                      )}
                    />
                  )
                }
              />
              <Route
                path="/intraworkout"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <IntraFetchFilter
                      userCountry={userCountry}
                      setIntraFilter={setIntraFilter}
                      setIntraFilterLink={setIntraFilterLink}
                      accessories={accessories}
                      ingredients={ingredients.filter(
                        (i) => i.category === "intraworkout"
                      )}
                    />
                  )
                }
              />
              <Route
                path="/intraworkout/:brand/:subtitle/:flavor/:servings/:region"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <IntraProduct
                      navBarOpen={navBarOpen}
                      intraFilter={intraFilter}
                      intraFilterLink={intraFilterLink}
                      ingredients={ingredients.filter(
                        (i) => i.category === "intraworkout"
                      )}
                    />
                  )
                }
              />
              <Route
                path="/magnesium/examples"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <MagnesiumExample
                      userCountry={userCountry}
                      setMagnesiumFilter={setMagnesiumFilter}
                      setMagnesiumFilterLink={setMagnesiumFilterLink}
                      accessories={accessories}
                      ingredients={ingredients.filter(
                        (i) => i.category === "magnesium"
                      )}
                    />
                  )
                }
              />
              <Route
                path="/magnesium"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <MagnesiumFetchFilter
                      userCountry={userCountry}
                      setMagnesiumFilter={setMagnesiumFilter}
                      setMagnesiumFilterLink={setMagnesiumFilterLink}
                      accessories={accessories}
                      ingredients={ingredients.filter(
                        (i) => i.category === "magnesium"
                      )}
                    />
                  )
                }
              />
              <Route
                path="/magnesium/:brand/:subtitle/:type/:flavor/:servings/:region"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <MagnesiumProduct
                      navBarOpen={navBarOpen}
                      magnesiumFilter={magnesiumFilter}
                      magnesiumFilterLink={magnesiumFilterLink}
                      ingredients={ingredients.filter(
                        (i) => i.category === "magnesium"
                      )}
                    />
                  )
                }
              />
              <Route
                path="/multivitamin/examples"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <MultivitaminExample
                      userCountry={userCountry}
                      setMultivitaminFilter={setMultivitaminFilter}
                      setMultivitaminFilterLink={setMultivitaminFilterLink}
                      accessories={accessories}
                      ingredients={ingredients.filter(
                        (i) => i.category === "multivitamin"
                      )}
                    />
                  )
                }
              />
              <Route
                path="/multivitamin"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <MultivitaminFetchFilter
                      userCountry={userCountry}
                      setMultivitaminFilter={setMultivitaminFilter}
                      setMultivitaminFilterLink={setMultivitaminFilterLink}
                      accessories={accessories}
                      ingredients={ingredients.filter(
                        (i) => i.category === "multivitamin"
                      )}
                    />
                  )
                }
              />
              <Route
                path="/multivitamin/:brand/:subtitle/:type/:flavor/:servings/:region"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <MultivitaminProduct
                      navBarOpen={navBarOpen}
                      multivitaminFilter={multivitaminFilter}
                      multivitaminFilterLink={multivitaminFilterLink}
                      ingredients={ingredients.filter(
                        (i) => i.category === "multivitamin"
                      )}
                    />
                  )
                }
              />
              <Route
                path="/massgainer"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <MassFetchFilter
                      userCountry={userCountry}
                      setMassFilter={setMassFilter}
                      setMassFilterLink={setMassFilterLink}
                      accessories={accessories}
                      ingredients={ingredients.filter(
                        (i) => i.category === "massgainer"
                      )}
                    />
                  )
                }
              />
              <Route
                path="/massgainer/:brand/:subtitle/:flavor/:servings/:region"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <MassProduct
                      navBarOpen={navBarOpen}
                      massFilter={massFilter}
                      massFilterLink={massFilterLink}
                      ingredients={ingredients.filter(
                        (i) => i.category === "massgainer"
                      )}
                    />
                  )
                }
              />
              <Route
                path="/omega3"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <OmegaFetchFilter
                      userCountry={userCountry}
                      setOmegaFilter={setOmegaFilter}
                      setOmegaFilterLink={setOmegaFilterLink}
                      accessories={accessories}
                      ingredients={ingredients.filter(
                        (i) => i.category === "omega3"
                      )}
                    />
                  )
                }
              />
              <Route
                path="/omega3/examples"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <OmegaExample
                      userCountry={userCountry}
                      setOmegaFilter={setOmegaFilter}
                      setOmegaFilterLink={setOmegaFilterLink}
                      accessories={accessories}
                      ingredients={ingredients.filter(
                        (i) => i.category === "omega3"
                      )}
                    />
                  )
                }
              />
              <Route
                path="/omega3/:brand/:subtitle/:type/:flavor/:servings/:region"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <OmegaProduct
                      navBarOpen={navBarOpen}
                      omegaFilter={omegaFilter}
                      omegaFilterLink={omegaFilterLink}
                      ingredients={ingredients.filter(
                        (i) => i.category === "omega3"
                      )}
                    />
                  )
                }
              />
              <Route
                path="/proteinbar"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <ProteinBarFetchFilter
                      userCountry={userCountry}
                      setProteinBarFilter={setProteinBarFilter}
                      setProteinBarFilterLink={setProteinBarFilterLink}
                      accessories={accessories}
                      ingredients={ingredients.filter(
                        (i) => i.category === "proteinbar"
                      )}
                    />
                  )
                }
              />
              <Route
                path="/proteinbar/examples"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <ProteinBarExample
                      userCountry={userCountry}
                      setProteinBarFilter={setProteinBarFilter}
                      setProteinBarFilterLink={setProteinBarFilterLink}
                      accessories={accessories}
                      ingredients={ingredients.filter(
                        (i) => i.category === "proteinbar"
                      )}
                    />
                  )
                }
              />
              <Route
                path="/proteinbar/:brand/:subtitle/:flavor/:servings/:region"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <ProteinBarProduct
                      navBarOpen={navBarOpen}
                      proteinBarFilter={proteinBarFilter}
                      proteinBarFilterLink={proteinBarFilterLink}
                      ingredients={ingredients.filter(
                        (i) => i.category === "proteinbar"
                      )}
                    />
                  )
                }
              />
              <Route
                path="/proteinpowder"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <ProteinFetchFilter
                      userCountry={userCountry}
                      setProteinFilter={setProteinFilter}
                      setProteinFilterLink={setProteinFilterLink}
                      accessories={accessories}
                      ingredients={ingredients.filter(
                        (i) => i.category === "proteinpowder"
                      )}
                    />
                  )
                }
              />
              <Route
                path="/proteinpowder/examples"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <ProteinExample
                      userCountry={userCountry}
                      setProteinFilter={setProteinFilter}
                      setProteinFilterLink={setProteinFilterLink}
                      accessories={accessories}
                      ingredients={ingredients.filter(
                        (i) => i.category === "proteinpowder"
                      )}
                    />
                  )
                }
              />
              <Route
                path="/proteinpowder/:brand/:type/:flavor/:size/:region"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <ProteinPowderProduct
                      navBarOpen={navBarOpen}
                      proteinFilter={proteinFilter}
                      proteinFilterLink={proteinFilterLink}
                      ingredients={ingredients.filter(
                        (i) => i.category === "proteinpowder"
                      )}
                    />
                  )
                }
              />
              <Route
                path="/preworkout"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <PreFetchFilter
                      userCountry={userCountry}
                      setPreFilter={setPreFilter}
                      setPreFilterLink={setPreFilterLink}
                      accessories={accessories}
                      ingredients={ingredients.filter(
                        (i) => i.category === "preworkout"
                      )}
                    />
                  )
                }
              />
              <Route
                path="/preworkout/examples"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <PreExample
                      userCountry={userCountry}
                      setPreFilter={setPreFilter}
                      setPreFilterLink={setPreFilterLink}
                      accessories={accessories}
                      ingredients={ingredients.filter(
                        (i) => i.category === "preworkout"
                      )}
                    />
                  )
                }
              />
              <Route
                path="/preworkout/:brand/:subtitle/:flavor/:servings/:region"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <PreworkoutProduct
                      navBarOpen={navBarOpen}
                      preFilter={preFilter}
                      preFilterLink={preFilterLink}
                      ingredients={ingredients.filter(
                        (i) => i.category === "preworkout"
                      )}
                    />
                  )
                }
              />
              <Route
                path="/turkesterone"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <TurkFetchFilter
                      userCountry={userCountry}
                      setTurkFilter={setTurkFilter}
                      setTurkFilterLink={setTurkFilterLink}
                      accessories={accessories}
                      ingredients={ingredients.filter(
                        (i) => i.category === "turkesterone"
                      )}
                    />
                  )
                }
              />
              <Route
                path="/turkesterone/:brand/:subtitle/:type/:flavor/:servings/:region"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <TurkProduct
                      navBarOpen={navBarOpen}
                      turkFilter={turkFilter}
                      turkFilterLink={turkFilterLink}
                      ingredients={ingredients.filter(
                        (i) => i.category === "turkesterone"
                      )}
                    />
                  )
                }
              />
              <Route path="*" element={<Error />} />
              {/* <Route path="/loading" element={<Loading />} /> */}
            </Routes>
            {/* </Suspense> */}
          </ContentWrap>
          <Footer />
        </PageContainer>
      </HelmetProvider>
    </>
  );
};

export default App;

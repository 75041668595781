import React, { useState, useEffect } from "react";
import { ReactTyped } from "react-typed";
import PopUp from "../components/PopUp";
import BrandListCompact from "../components/BrandListCompact";
import { tieBreaker, searchPageGen, submaker } from "../custom/functions";
import { IngredientEyeSVG, LinkSVG } from "../custom/svg";
import podiumGlyph from "../images/podiumglyph.png";
import { LiveIndicator } from "../custom/animations";
import { Helmet } from "react-helmet-async";
import AdPopUp from "../components/AdPopUp";
import {
  BackDiv,
  BackButton,
  BackText,
  ResponseDiv,
  ResponseTitle,
  ResponseSubtitle,
  SvgWrapper,
  PodiumDivMobile,
  FilterButtonDiv,
  ShowMoreButton,
  PodiumGlyphImg,
  TabBar,
} from "../css/examplePage";

const MultivitaminExample = (props) => {
  const [exampleLoading, setExampleLoading] = useState(true);
  const [exampleOne, setExampleOne] = useState([]);
  const [exampleTwo, setExampleTwo] = useState([]);
  const [exampleThree, setExampleThree] = useState([]);
  const [exampleFour, setExampleFour] = useState([]);
  const [exampleFive, setExampleFive] = useState([]);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [popupIngredients, setPopupIngredients] = useState(null);
  const [popupIngredientList, setPopupIngredientList] = useState(null);

  const closePopup = () => setPopupOpen(false);

  const handlePopUp = (ingredients, ingredientList) => {
    setPopupOpen(true);
    setPopupIngredients(ingredients);
    setPopupIngredientList(ingredientList);
  };

  useEffect(() => {
    if (!props.userCountry) return;

    const fetchExampleData = (url) => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await fetch(url, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          });
          if (response.ok) {
            const data = await response.json();
            resolve(data);
          } else {
            reject(new Error(`HTTP error! status: ${response.status}`));
          }
        } catch (error) {
          reject(error);
        }
      });
    };

    const fetchAllExamples = (userCountry) => {
      let fetchOne;
      let fetchTwo;
      let fetchThree;
      let fetchFour;
      let fetchFive;

      fetchOne = fetchExampleData(
        `/api/multivitamin?ingredient1=vitaminb6&ingredient2=vitaminb12&ingredient3=vitamine&ingredient4=selenium&region=` +
          userCountry
      );
      fetchTwo = fetchExampleData(
        `/api/multivitamin?ingredient1=vitaminc&ingredient2=vitamind&ingredient3=zinc&region=` +
          userCountry
      );
      fetchThree = fetchExampleData(
        `/api/multivitamin?ingredient1=calcium&ingredient2=magnesium&ingredient3=vitaminc&ingredient4=vitamind&ingredient5=vitamine&region=` +
          userCountry
      );
      fetchFour = fetchExampleData(
        `/api/multivitamin?ingredient1=folate&ingredient2=iron&ingredient3=vitaminb6&ingredientb12&region=` +
          userCountry
      );
      fetchFive = fetchExampleData(
        `/api/multivitamin?ingredient1=vitamina&ingredient2=vitaminb1&ingredient3=vitaminb2&ingredient4=niacin&ingredient5=pantothenicacid&ingredient6=vitaminb6&ingredient7=biotin&ingredient8=folate&ingredient9=vitaminb12&ingredient10=vitaminc&ingredient11=vitamind&ingredient12=vitamine&ingredient13=vitamink&region=` +
          userCountry
      );

      setExampleLoading(true);

      Promise.all([
        fetchOne,
        fetchTwo,
        fetchThree,
        fetchFour,
        fetchFive,
      ])
        .then(([dataOne, dataTwo, dataThree, dataFour, dataFive]) => {
          setExampleOne(dataOne);
          setExampleTwo(dataTwo);
          setExampleThree(dataThree);
          setExampleFour(dataFour);
          setExampleFive(dataFive);
          setExampleLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    fetchAllExamples(props.userCountry);
    // props.setPreFilter([]);
    // props.setPreFilterLink(null);
    // props.setProteinFilter([]);
    // props.setProteinFilterLink(null);
    // props.setElectrolyteFilter([]);
    // props.setElectrolyteFilterLink(null);
  }, [props.userCountry]);

  return (
    <center>
      <Helmet>
        <title>Multivitamin Ranking Examples</title>
        <meta
          name="description"
          content="Discover the best value multivitamin with our ingredient value ranking system. Whether you’re looking for essential nutrients like Biotin, Folate, Magnesium, Vitamin B12, Vitamin C, or Vitamin D, our rankings highlight top multivitamin products based on value."
        />
        <meta
          name="keywords"
          content="best multivitamin, multivitamin rankings, affordable multivitamin supplements, high-quality multivitamin formula, multivitamin ingredient comparison, complete daily multivitamin, multivitamin for recovery and performance, essential nutrients multivitamin, multivitamin value ranking, high-absorption multivitamin, vitamins for workout recovery, multivitamin for muscle health, ingredient-based multivitamin rankings, effective multivitamin ingredients, multivitamin with key nutrients, affordable daily vitamins, multivitamin for active lifestyles, performance-focused multivitamin, multivitamin supplement definitions, all-in-one daily multivitamin"
        />
        <meta property="og:title" content="Multivitamin Ranking Examples" />
        <meta
          property="og:description"
          content="best multivitamin, multivitamin rankings, affordable multivitamin supplements, high-quality multivitamin formula, multivitamin ingredient comparison, complete daily multivitamin, multivitamin for recovery and performance, essential nutrients multivitamin, multivitamin value ranking, high-absorption multivitamin, vitamins for workout recovery, multivitamin for muscle health, ingredient-based multivitamin rankings, effective multivitamin ingredients, multivitamin with key nutrients, affordable daily vitamins, multivitamin for active lifestyles, performance-focused multivitamin, multivitamin supplement definitions, all-in-one daily multivitamin"
        />
      </Helmet>
      <BackDiv>
        {/* <StyledLink to={props.preFilterLink} method="get"> */}
        <BackButton to={"/multivitamin?ingredient1=vitaminc&ingredient2=vitamind"}>
          <LinkSVG />
          <BackText>Multivitamin Filter</BackText>
        </BackButton>
        <LiveIndicator />
      </BackDiv>
      <ResponseDiv>
        <ResponseTitle>
          <ReactTyped
            strings={["Complete Multivitamin"]}
            typeSpeed={30}
            showCursor={false}
          />
        </ResponseTitle>
        <ResponseSubtitle
          onClick={() =>
            handlePopUp(
              [
                "vitamina",
                "vitaminb1",
                "vitaminb2",
                "niacin",
                "pantothenicacid",
                "vitaminb6",
                "biotin",
                "folate",
                "vitaminb12",
                "vitaminc",
                "vitamind",
                "vitamine",
                "vitamink",
              ],
              props.ingredients
            )
          }
        >
          <SvgWrapper>
            <IngredientEyeSVG />
          </SvgWrapper>
          {submaker(
            [
              "vitamina",
              "vitaminb1",
              "vitaminb2",
              "niacin",
              "pantothenicacid",
              "vitaminb6",
              "biotin",
              "folate",
              "vitaminb12",
              "vitaminc",
              "vitamind",
              "vitamine",
              "vitamink",
            ],
            [],
            props.ingredients
          )}
        </ResponseSubtitle>
      </ResponseDiv>
      <PodiumDivMobile>
        {!exampleLoading ? (
          tieBreaker(exampleFive).map(
            (item, index) =>
              item && (
                <BrandListCompact
                  item={item}
                  filterRank={item.currentRank}
                  oldRank={item.oldRank}
                  ticker={true}
                  loading={false}
                  userCountry={props.userCountry}
                  ai={true}
                  key={index}
                  filter={exampleFive}
                  filterLink={searchPageGen(
                    "multivitamin",
                    null,
                    null,
                    null,
                    null,
                    [
                        "vitamina",
                        "vitaminb1",
                        "vitaminb2",
                        "niacin",
                        "pantothenicacid",
                        "vitaminb6",
                        "biotin",
                        "folate",
                        "vitaminb12",
                        "vitaminc",
                        "vitamind",
                        "vitamine",
                        "vitamink",
                      ],
                    [],
                    [],
                    props.ingredients
                  )}
                  setFilter={props.setMultivitaminFilter}
                  setFilterLink={props.setMultivitaminFilterLink}
                />
              )
          )
        ) : (
          <>
            <BrandListCompact filterRank={1} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={2} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={3} loading={true}></BrandListCompact>
          </>
        )}
      </PodiumDivMobile>
      <FilterButtonDiv>
        <ShowMoreButton
          to={searchPageGen(
            "multivitamin",
            null,
            null,
            null,
            null,
            [
                "vitamina",
                "vitaminb1",
                "vitaminb2",
                "niacin",
                "pantothenicacid",
                "vitaminb6",
                "biotin",
                "folate",
                "vitaminb12",
                "vitaminc",
                "vitamind",
                "vitamine",
                "vitamink",
              ],
            [],
            [],
            props.ingredients
          )}
        >
          <PodiumGlyphImg src={podiumGlyph} alt="show more button" />
        </ShowMoreButton>
      </FilterButtonDiv>
      <TabBar />
      <ResponseDiv>
        <ResponseTitle>
          <ReactTyped
            strings={["Immune Support Multivitamin"]}
            typeSpeed={30}
            showCursor={false}
          />
        </ResponseTitle>
        <ResponseSubtitle
          onClick={() =>
            handlePopUp(["vitaminc", "vitamind", "zinc"], props.ingredients)
          }
        >
          <SvgWrapper>
            <IngredientEyeSVG />
          </SvgWrapper>
          {submaker(["vitaminc", "vitamind", "zinc"], [], props.ingredients)}
        </ResponseSubtitle>
      </ResponseDiv>
      <PodiumDivMobile>
        {!exampleLoading ? (
          tieBreaker(exampleTwo).map(
            (item, index) =>
              item && (
                <BrandListCompact
                  item={item}
                  filterRank={item.currentRank}
                  oldRank={item.oldRank}
                  ticker={true}
                  loading={false}
                  userCountry={props.userCountry}
                  ai={true}
                  key={index}
                  filter={exampleTwo}
                  filterLink={searchPageGen(
                    "multivitamin",
                    null,
                    null,
                    null,
                    null,
                    ["vitaminc", "vitamind", "zinc"],
                    [],
                    [],
                    props.ingredients
                  )}
                  setFilter={props.setMultivitaminFilter}
                  setFilterLink={props.setMultivitaminFilterLink}
                />
              )
          )
        ) : (
          <>
            <BrandListCompact filterRank={1} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={2} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={3} loading={true}></BrandListCompact>
          </>
        )}
      </PodiumDivMobile>
      <FilterButtonDiv>
        <ShowMoreButton
          to={searchPageGen(
            "multivitamin",
            null,
            null,
            null,
            null,
            ["vitaminc", "vitamind", "zinc"],
            [],
            [],
            props.ingredients
          )}
        >
          <PodiumGlyphImg src={podiumGlyph} alt="show more button" />
        </ShowMoreButton>
      </FilterButtonDiv>
      <TabBar />
      <ResponseDiv>
        <ResponseTitle>
          <ReactTyped
            strings={["Bone + Joint Multivitamin"]}
            typeSpeed={30}
            showCursor={false}
          />
        </ResponseTitle>
        <ResponseSubtitle
          onClick={() =>
            handlePopUp(
              ["calcium", "magnesium", "vitaminc", "vitamind", "vitamine"],
              props.ingredients
            )
          }
        >
          <SvgWrapper>
            <IngredientEyeSVG />
          </SvgWrapper>
          {submaker(
            ["calcium", "magnesium", "vitaminc", "vitamind", "vitamine"],
            [],
            props.ingredients
          )}
        </ResponseSubtitle>
      </ResponseDiv>
      <PodiumDivMobile>
        {!exampleLoading ? (
          tieBreaker(exampleThree).map(
            (item, index) =>
              item && (
                <BrandListCompact
                  item={item}
                  filterRank={item.currentRank}
                  oldRank={item.oldRank}
                  ticker={true}
                  loading={false}
                  userCountry={props.userCountry}
                  ai={true}
                  key={index}
                  filter={exampleThree}
                  filterLink={searchPageGen(
                    "multivitamin",
                    null,
                    null,
                    null,
                    null,
                    ["calcium", "magnesium", "vitaminc", "vitamind", "vitamine"],
                    [],
                    [],
                    props.ingredients
                  )}
                  setFilter={props.setMagnesiumFilter}
                  setFilterLink={props.setMagnesiumFilter}
                />
              )
          )
        ) : (
          <>
            <BrandListCompact filterRank={1} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={2} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={3} loading={true}></BrandListCompact>
          </>
        )}
      </PodiumDivMobile>
      <FilterButtonDiv>
        <ShowMoreButton
          to={searchPageGen(
            "multivitamin",
            null,
            null,
            null,
            null,
            ["calcium", "magnesium", "vitaminc", "vitamind", "vitamine"],
            [],
            [],
            props.ingredients
          )}
        >
          <PodiumGlyphImg src={podiumGlyph} alt="show more button" />
        </ShowMoreButton>
      </FilterButtonDiv>
      <TabBar />
      <ResponseDiv>
        <ResponseTitle>
          <ReactTyped
            strings={["Energy + Metabolism Multivitamin"]}
            typeSpeed={30}
            showCursor={false}
          />
        </ResponseTitle>
        <ResponseSubtitle
          onClick={() =>
            handlePopUp(
              ["folate", "iron", "vitaminb6", "vitaminb12"],
              props.ingredients
            )
          }
        >
          <SvgWrapper>
            <IngredientEyeSVG />
          </SvgWrapper>
          {submaker(
            ["folate", "iron", "vitaminb6", "vitaminb12"],
            [],
            props.ingredients
          )}
        </ResponseSubtitle>
      </ResponseDiv>
      <PodiumDivMobile>
        {!exampleLoading ? (
          tieBreaker(exampleFour).map(
            (item, index) =>
              item && (
                <BrandListCompact
                  item={item}
                  filterRank={item.currentRank}
                  oldRank={item.oldRank}
                  ticker={true}
                  loading={false}
                  userCountry={props.userCountry}
                  ai={true}
                  key={index}
                  filter={exampleThree}
                  filterLink={searchPageGen(
                    "multivitamin",
                    null,
                    null,
                    null,
                    null,
                    ["folate", "iron", "vitaminb6", "vitaminb12"],
                    [],
                    [],
                    props.ingredients
                  )}
                  setFilter={props.setMultivitaminFilter}
                  setFilterLink={props.setMultivitaminFilter}
                />
              )
          )
        ) : (
          <>
            <BrandListCompact filterRank={1} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={2} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={3} loading={true}></BrandListCompact>
          </>
        )}
      </PodiumDivMobile>
      <FilterButtonDiv>
        <ShowMoreButton
          to={searchPageGen(
            "multivitamin",
            null,
            null,
            null,
            null,
            ["folate", "iron", "vitaminb6", "vitaminb12"],
            [],
            [],
            props.ingredients
          )}
        >
          <PodiumGlyphImg src={podiumGlyph} alt="show more button" />
        </ShowMoreButton>
      </FilterButtonDiv>
      <TabBar />
      <ResponseDiv>
        <ResponseTitle>
          <ReactTyped
            strings={["Performance + Recovery Multivitamin"]}
            typeSpeed={30}
            showCursor={false}
          />
        </ResponseTitle>
        <ResponseSubtitle
          onClick={() =>
            handlePopUp(
              ["selenium", "vitaminb6", "vitaminb12", "vitamine"],
              props.ingredients
            )
          }
        >
          <SvgWrapper>
            <IngredientEyeSVG />
          </SvgWrapper>
          {submaker(
            ["selenium", "vitaminb6", "vitaminb12", "vitamine"],
            [],
            props.ingredients
          )}
        </ResponseSubtitle>
      </ResponseDiv>
      <PodiumDivMobile>
        {!exampleLoading ? (
          tieBreaker(exampleOne).map(
            (item, index) =>
              item && (
                <BrandListCompact
                  item={item}
                  filterRank={item.currentRank}
                  oldRank={item.oldRank}
                  ticker={true}
                  loading={false}
                  userCountry={props.userCountry}
                  ai={true}
                  key={index}
                  filter={exampleOne}
                  filterLink={searchPageGen(
                    "multivitamin",
                    null,
                    null,
                    null,
                    null,
                    ["selenium", "vitaminb6", "vitaminb12", "vitamine"],
                    [],
                    [],
                    props.ingredients
                  )}
                  setFilter={props.setMultivitaminFilter}
                  setFilterLink={props.setMultivitaminFilter}
                />
              )
          )
        ) : (
          <>
            <BrandListCompact filterRank={1} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={2} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={3} loading={true}></BrandListCompact>
          </>
        )}
      </PodiumDivMobile>
      <FilterButtonDiv>
        <ShowMoreButton
          to={searchPageGen(
            "multivitamin",
            null,
            null,
            null,
            null,
            ["selenium", "vitaminb6", "vitaminb12", "vitamine"],
            [],
            [],
            props.ingredients
          )}
          style={{ marginBottom: "25px" }}
        >
          <PodiumGlyphImg src={podiumGlyph} alt="show more button" />
        </ShowMoreButton>
      </FilterButtonDiv>
      {/* <LastTabBar /> */}
      <PopUp
        isOpen={isPopupOpen}
        onClose={closePopup}
        ingredients={popupIngredients}
        ingredientList={popupIngredientList}
      />
      <AdPopUp canOpen={!isPopupOpen} accessories={props.accessories} />
    </center>
  );
};

export default MultivitaminExample;
